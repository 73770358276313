import React, { useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames/bind';
import history from '../../history';
import {
  REACTIVE_REPORT_TYPES,
  PRE_MADE_DOCUMENTS,
  DOCUMENT_TYPES
} from '../../constants/constants';
import {
  fetchCustomDocumentTemplates,
  fetchEmployeeAnnouncement
} from '../../api/v4';
import svgMapper from '../../utils/svgMapper';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import { fetchIncidentBasicsRequest } from '../../actions/incidents';
import { setActiveSafetyWalk } from '../../actions/safetyWalks';
import { setActiveTaskRequest } from '../../actions/tasks';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import { getLoggedInUser } from '../../selectors/users';
import { Button, Dropdown } from '../../components/inputs';
import DashboardButtons from '../../components/Modal/dashboardButtons';
import Modal from '../../components/Modal';

import styles from './dashboard.module.scss';
import customToast from '../../utils/customToast';

export default function Dashboard() {
  const dispatch = useDispatch();
  const locationState = useLocation()?.state;
  const user = useSelector(getLoggedInUser);
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();

  const [modalOpen, setModal] = useState(null);
  const [notificationModal, setNotificationModal] = useState(false);
  const [customDocTemplates, setDocTemplates] = useState([]);
  const [modalSelection, setModalSelection] = useState(null);
  const [hideButtons, setHideButtons] = useState(false);

  const handleOpenModal = modalId => {
    setModal(modalId);
  };
  const handleCloseModal = () => {
    setModalSelection(null);
    setModal(null);
  };

  const modalProps = {
    reports: {
      title: 'Create New Incident Report',
      text: 'Select one or more if applicable',
      buttons: REACTIVE_REPORT_TYPES?.filter(
        rt => activeCompany?.showGeneral || rt.value !== 19
      )
        ?.filter(rt => !activeCompany?.usesClaimsHub || rt.value !== 17)
        ?.map(rt => ({
          ...rt,
          disabled:
            rt.value === 19
              ? modalSelection?.length && !modalSelection?.includes(19)
              : modalSelection?.includes(19)
        })),
      onClick: v => {
        modalSelection?.includes(v)
          ? setModalSelection(modalSelection.filter(x => x !== v))
          : setModalSelection(
              !modalSelection ? [].concat(v) : modalSelection.concat(v)
            );
      },
      modalSubmit: () => {
        dispatch(
          fetchIncidentBasicsRequest({ reportTemplateIds: modalSelection })
        );
      }
    },
    audits: {
      title: 'Create New Audit',
      buttons: [
        { label: 'Safety Audit', svg: 'safetyAudit', value: 'safety' },
        { label: 'Quality Audit', svg: 'qualityAudit', value: 'quality' },
        {
          label: 'Lockout/Tagout Procedures',
          svg: 'lockout-tagout',
          value: 'lockoutTagout'
        }
      ],
      modalSubmit: () => {
        modalSelection === 'safety'
          ? history.push('/app/audits/safety/create')
          : modalSelection === 'quality'
            ? history.push('/app/audits/quality/create')
            : history.push('/app/audits/loto/create');
      }
    },
    observation: {
      title: 'Create New Observation',
      buttons: [
        { label: 'Behavior Observation', svg: 'bo', value: 'bo' },
        {
          label: activeCompany.isDash ? 'DASH Report' : 'Safety Walk',
          svg: 'safetyWalk',
          value: 'sw'
        },
        { label: 'Safety Suggestion', svg: 'safety-suggestion', value: 'ss' }
      ],
      modalSubmit: () => {
        if (modalSelection === 'bo') {
          history.push('/app/behaviorObservation/container');
        } else if (modalSelection === 'sw') {
          dispatch(setActiveSafetyWalk({}));
          history.push('/app/safetyWalkContainer');
        } else if (modalSelection === 'ss') {
          history.push('/app/safetySuggestions');
        }
      }
    },
    training: {
      title: 'Create New Training',
      buttons: [
        { label: 'Training', svg: 'safetyTraining', value: 'training' },
        { label: 'Training Course', svg: 'courses', value: 'course' }
      ],
      modalSubmit: () => {
        if (modalSelection === 'training') {
          localStorage.removeItem('trainingTemplate');
          history.push({
            pathname: '/app/training/create/',
            state: { fromScreen: '/app/dashboard' }
          });
        } else if (modalSelection === 'course') {
          history.push({
            pathname: '/app/trainingCourses/create/',
            state: { fromScreen: '/app/dashboard' }
          });
        }
      }
    },
    customDoc: {
      title: 'Create Custom Document',
      dropdown: (
        <Dropdown
          className={styles.grow}
          fieldLabel="Select Custom Document to create"
          valueKey={'_id'}
          options={customDocTemplates}
          currentValue={modalSelection}
          onChange={c => setModalSelection(c)}
        />
      ),
      modalSubmit: () => {
        history.push({
          pathname: `/app/customDocumentContainer/${modalSelection}`,
          state: { fromScreen: '/app/dashboard' }
        });
      }
    },
    document: {
      title: 'Create New Document',
      buttons: DOCUMENT_TYPES,
      modalSubmit: () => {
        modalSelection === 'upload'
          ? history.push('/app/myDocumentContainer')
          : setModalSelection(null, handleOpenModal('premadeDoc'));
      }
    },

    premadeDoc: {
      title: 'Create New Premade Document',
      buttons: PRE_MADE_DOCUMENTS,
      modalSubmit: () => {
        modalSelection === 'rec'
          ? history.push('/app/recurrenceContainer')
          : modalSelection === 'trans'
            ? history.push('/app/transitionContainer')
            : history.push('/app/fireexposure');
      }
    }
  };

  const dashboardButtons = [
    {
      text: 'Incident Report',
      svg: 'Incidents',
      permission: [100, 400, 500, 900],
      onClick: () => handleOpenModal('reports')
    },
    {
      text: 'Audit',
      svg: 'qualityAudit',
      permission: [400, 500, 900],
      onClick: () => handleOpenModal('audits')
    },
    {
      text: 'Observation',
      svg: 'safetyWalk',
      permission: [100, 400, 500, 900],
      onClick: () => handleOpenModal('observation')
    },
    {
      text: 'Training',
      svg: 'safetyTraining',
      permission: [400, 500, 900],
      onClick: () => handleOpenModal('training')
    },
    {
      text: 'Task',
      svg: 'Tasks',
      permission: [400, 500, 900],
      onClick: () => dispatch(setActiveTaskRequest({}))
    },
    {
      text: 'Safety Meeting',
      svg: 'safetyAudit',
      permission: [400, 500, 900],
      onClick: () => history.push('/app/safetyMeeting/container')
    },
    {
      text: 'Custom Document',
      svg: 'CustomDocument',
      permission: [100, 400, 500, 900],
      onClick: () => handleOpenModal('customDoc')
    },
    {
      text: 'Document',
      svg: 'sds',
      permission: [100, 400, 500, 900],
      onClick: () => handleOpenModal('document')
    }
  ];

  useEffect(() => {
    setNotificationModal(
      locationState?.showNotificationModal && user.accessLevel > 100
    );
    if (activeProject?.isHidden || activeGroup?.isHidden) setHideButtons(true);
    // only want to show them once when were coming from logging in
    if (locationState?.showToasts) {
      fetchEmployeeAnnouncement(user._id).then(response => {
        if (response) {
          customToast('Your company has sent out an Announcment!', 'info', {
            onClick: event => {
              // this is making sure we only go here if the close button wasn't clicked
              if (!event.target.closest('.Toastify__close-button')) {
                history.push(`/app/announcement/container/${response._id}`, {
                  state: { fromToast: true }
                });
              }
            },
            autoClose: false,
            closeOnClick: true
          });
        }
        history.replace({ state: { ...locationState, showToasts: false } });
      });
    }
    fetchCustomDocumentTemplates().then(setDocTemplates);
  }, [
    dispatch,
    locationState,
    user._id,
    user.accessLevel,
    activeGroup,
    activeProject
  ]);
  const header = <Header title="Home" />;

  return (
    <HeaderAndFooter
      Header={header}
      className={styles.dashboard}
      cards="dashboard"
    >
      {hideButtons ? null : (
        <>
          <span className={styles.sectionLabel}>Create a new</span>
          <div className={styles.buttonContainer}>
            {dashboardButtons
              .filter(b => b.permission.includes(user.accessLevel))
              .map((x, index) => (
                <div key={index}>
                  <div
                    data-tooltip-id={x.text}
                    data-tooltip-class-name={styles.tooltip}
                  >
                    <Button
                      id={x.text}
                      className={styles.button}
                      image={svgMapper(x.svg)}
                      imageClass={classnames(
                        styles.imageStyles,
                        styles.notSelected
                      )}
                      inputClassName={styles.button}
                      text={<p className={styles.buttonText}>{x.text}</p>}
                      onClick={x.onClick}
                      testID={x.text}
                      isDashboard
                    />
                  </div>
                  <ReactTooltip
                    className={classnames(styles.tooltip, 'customTheme')}
                    id={x.text}
                  >
                    {x.text}
                  </ReactTooltip>
                </div>
              ))}
          </div>
        </>
      )}
      <DashboardButtons
        modalOpen={modalOpen}
        modalProps={modalProps}
        handleCloseModal={handleCloseModal}
        modalSelection={modalSelection}
        setModalSelection={setModalSelection}
        company={activeCompany}
      />
      <Modal
        title="Notifications"
        titleClassName="blueHeader"
        isOpen={notificationModal}
        onRequestClose={() => setNotificationModal(false)}
        submitActions={() =>
          history.push({
            pathname: '/app/profile',
            state: { setUp: true }
          })
        }
        submitButtonText="Set Notifications"
        submitButtonColor="blue"
        cancelButtonText="Remind Me Later"
      >
        Would you like to set up your notifications?
      </Modal>
    </HeaderAndFooter>
  );
}
