import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import moment from 'moment';
import history from '../../../history';
import {
  getTrainingCourses,
  fetchDocumentFoldersByType,
  updateTrainingCoursesFolder,
  sendCourseReminder,
  archiveMany,
  fetchUserListFilter,
  updateUserListFilter
} from '../../../api/v4';
import { getActiveFolderSelector } from '../../../selectors/documents';
import { getLoggedInUser } from '../../../selectors/users';
import { usePrevious } from '../../../utils/hooks';
import useActiveHeirarchy from '../../../utils/useActiveHeirarchy';
import AnalyticsPeriodPicker from '../../../components/AnalyticsPeriodPicker';
import AddToFolderModal from '../../../components/Modal/addToFolderModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import List from '../../../components/List';
import Modal from '../../../components/Modal';
import { Dropdown } from '../../../components/inputs';
import DocumentFolder from '../../../components/DocumentFolder';
import TypeSelection from '../../../components/TypeSelection';

export default function CourseList() {
  const activeFolder = useSelector(getActiveFolderSelector);
  const user = useSelector(getLoggedInUser);
  const { activeGroup, activeProject } = useActiveHeirarchy();
  const [openModal, setOpenModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [courses, setCourses] = useState([]);
  const [folders, setFolders] = useState([]);
  const [ids, setIds] = useState([]);
  const [folderId, setFolderId] = useState();
  const [addToFolderModal, setAddToFolderModal] = useState(false);
  const [listType, setListType] = useState('Active');
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();

  const prevPeriod = usePrevious(currentPeriod);
  const prevFolder = usePrevious(activeFolder);
  const prevType = usePrevious(listType);

  useEffect(() => {
    fetchUserListFilter(user._id, 'trainingCourse').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });
  }, [user._id]);

  useEffect(() => {
    if (
      currentPeriod &&
      (!isEqual(prevPeriod, currentPeriod) ||
        !isEqual(prevFolder, activeFolder) ||
        !isEqual(prevType, listType))
    )
      getTrainingCourses(activeFolder?._id ?? '', {
        ...currentPeriod,
        selectedType: listType
      }).then(res => {
        setCourses(res);
      });

    fetchDocumentFoldersByType('TrainingCourses').then(response =>
      setFolders(response)
    );
  }, [activeFolder, prevPeriod, currentPeriod, prevFolder, listType, prevType]);

  const templateDropdown = courses?.map(t => {
    return { value: t._id, label: t.title };
  });

  const header = (
    <Header
      title="Training Courses"
      rightButtons={{
        text: 'Create Training Course',
        visible: !activeGroup?.isHidden && !activeProject?.isHidden,
        color: 'blue',
        onClick: () => {
          history.push('/app/trainingCourses/create/');
        }
      }}
    />
  );

  const handleViewEdit = template => {
    localStorage.removeItem('trainingCourse');
    localStorage.removeItem('trainingTemplate');
    history.push(`/app/trainingCourses/overallView/${template._id}`);
  };

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'Training Course',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() =>
      getTrainingCourses(activeFolder?._id ?? '', {
        ...currentPeriod,
        selectedType: listType
      }).then(setCourses)
    );
  };

  const columns = [
    { key: 'title' },
    ...(listType === 'Active'
      ? [
          {
            key: 'status',
            accessor: r => r.completionStatus,
            enum: [
              'Assigned',
              'Completed',
              'In-Progress',
              'Awaiting Approval',
              'Past Due',
              'Future Assignment Scheduled'
            ]
          }
        ]
      : []),
    {
      key: 'numTrainings',
      label: 'Number of Trainings',
      accessor: r => r.trainingTemplates?.length
    },
    {
      key: 'dueDate',
      datatype: 'date',
      accessor: r => (r.dueDate ? moment(r.dueDate).format('MM/DD/YYYY') : null)
    },
    { key: 'completionDate', datatype: 'date' },
    {
      key: 'assignedTo',
      label: 'Employees',
      datatype: 'users',
      accessor: r => (r.assignByJobTitles?.length ? null : r.assignedTo)
    },
    {
      key: 'assignByJobTitles',
      label: 'Job Title',
      accessor: r => r.assignByJobTitles?.join(', ')
    }
  ];

  return (
    <HeaderAndFooter Header={header}>
      {user.accessLevel > 400 ? (
        <TypeSelection
          selectedArray={['Active', 'Archived']}
          onClick={setListType}
          selected={listType}
        />
      ) : null}
      <AnalyticsPeriodPicker
        period={currentPeriod}
        onChange={setCurrentPeriod}
        onSave={v =>
          updateUserListFilter({
            empId: user._id,
            type: 'trainingCourse',
            period: v
          }).then(() => setEmpPeriod(v))
        }
        allowAll
        savedPeriod={empPeriod}
        listPicker
      />
      <DocumentFolder content={courses} docType="TrainingCourses" />
      <List
        saveKey={`${listType}trainingCourseList`}
        rowClick={handleViewEdit}
        getRowId={r => r._id}
        dataIsHash
        data={courses}
        settings={columns}
        actions={[
          ...(user.accessLevel > 400
            ? (listType === 'Active'
                ? [
                    {
                      label: 'Send Reminder',
                      color: 'blue',
                      onClick: ids =>
                        sendCourseReminder({
                          courseIds: ids
                        })
                    }
                  ]
                : []
              ).concat([
                {
                  label: `${
                    listType === 'Archived' ? 'Un-' : ''
                  }Archive Courses`,
                  color: 'blue',
                  onClick: handleArchive
                }
              ])
            : []),
          {
            color: 'blue',
            label: 'Add to Folder',
            onClick: ids => {
              setAddToFolderModal(true);
              setIds(ids);
            }
          }
        ]}
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => setOpenModal(false)}
        submitActions={() => handleViewEdit(currentTemplate, true)}
        testID="Training Template"
      >
        <Dropdown
          options={templateDropdown}
          fieldLabel="Training Template"
          currentValue={currentTemplate}
          isRequired
          onChange={setCurrentTemplate}
          placeholder="Select a template"
          searchable
        />
      </Modal>
      <AddToFolderModal
        isOpen={addToFolderModal}
        folderId={folderId}
        onRequestClose={() => setAddToFolderModal(false)}
        submitActions={() => {
          updateTrainingCoursesFolder({ ids, folderId }).then(r => {
            setAddToFolderModal(false);
            setFolderId();
          });
        }}
        folderOptions={folders?.map(f => ({ value: f._id, label: f?.name }))}
        onChange={setFolderId}
      />
    </HeaderAndFooter>
  );
}
