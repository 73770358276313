import axios from 'axios';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import config from '../config/config';
import history from '../history';
import store from '../store';
import { START_LOADING, STOP_LOADING } from '../constants/actionTypes';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import { getLoggedInUser } from '../selectors/users';
import { MOBILE_MIN_VERSION } from '../constants/constants';
import customToast from '../utils/customToast';

export function setTokenHeader() {
  return sessionStorage.getItem('token');
}

export function getQueryString(options = {}) {
  return Object.keys(options)
    .reduce((acc, cur) => {
      const name = encodeURIComponent(cur);
      let val = options[cur];
      if (val !== undefined) {
        if (Array.isArray(val)) {
          val.forEach(v => acc.push(`${name}[]=${encodeURIComponent(v)}`));
        } else {
          acc.push(`${name}=${encodeURIComponent(val)}`);
        }
      }
      return acc;
    }, [])
    .join('&');
}

axios.default.withCredentials = true;

axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
  'Content-Type': 'application/json'
};

const v4Api = axios.create({ baseURL: `${config.apiUrl}/v4` });
const v4ApiNoLoading = axios.create({ baseURL: `${config.apiUrl}/v4` });

const requestAuth = config => {
  if (!config.headers.Authorization) {
    const token = setTokenHeader();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else if (config.allowNoToken) {
      config.headers['fromLogin'] = 'true';
    }
  }

  const userAgent = window.navigator.userAgent;

  if ((!isDesktop && !isMobileOnly) || userAgent.includes('CrOS')) {
    config.headers['ireport-mobile-version'] = MOBILE_MIN_VERSION;
  }

  return config;
};

const requestLoading = config => {
  store.dispatch({ type: START_LOADING });

  return config;
};

const requestAddHierarchyId = config => {
  if (!config.hierarchy) return config;

  const state = store.getState();
  const companyId = getActiveCompany(state)?._id;
  const locationId = getActiveLocationId(state)?._id;
  const projectId = getActiveProject(state)?._id;
  const defaultCompanyId = state.company?.list?.[0]?._id;

  const hierarchyId =
    config.hierarchy === 'company'
      ? companyId
      : config.hierarchy === 'employeeHierarchy'
        ? (locationId ?? companyId)
        : (projectId ?? locationId ?? companyId ?? defaultCompanyId);

  config.url = `/${hierarchyId}${config.url}`;
  return config;
};

const responseStop = response => {
  store.dispatch({ type: STOP_LOADING });

  return response;
};

const errorStop = error => {
  store.dispatch({ type: STOP_LOADING });
  throw error;
};

const errorHandler = error => {
  if (!error.response) throw error;

  if (error.response.status === 500 || error.response.status === 418) {
    if (process.env.NODE_ENV === 'development') throw error.response;
    else history.push('/error');
  }

  if (error.response.status === 403) {
    history.push('/login');
  }
  throw error.response;
};

v4ApiNoLoading.interceptors.request.use(requestAuth);
v4ApiNoLoading.interceptors.request.use(requestAddHierarchyId);
v4ApiNoLoading.interceptors.response.use(null, errorHandler);

v4Api.interceptors.request.use(requestAuth);
v4Api.interceptors.request.use(requestAddHierarchyId);
v4Api.interceptors.request.use(requestLoading);
v4Api.interceptors.response.use(responseStop, errorStop);
v4Api.interceptors.response.use(null, errorHandler);

const v4APICall = (
  url,
  {
    method = 'get',
    data = undefined,
    loading = true,
    hierarchy = true, // true, false, 'employeeHierarchy' or 'company'
    options = undefined,
    toast = false,
    error = false,
    allowNoToken = false,
    transformRequest,
    responseType
  } = {}
) => {
  if (options) url += '?' + getQueryString(options);

  return (loading ? v4Api : v4ApiNoLoading)
    .request({
      url,
      method,
      hierarchy,
      data,
      allowNoToken,
      transformRequest,
      responseType
    })
    .then(response => {
      if (toast) {
        customToast(response.data, 'success');
      }
      return response.data;
    })
    .catch(err => {
      if (error) customToast(err.data, 'error');
      else throw err;

      console.log(err);
    });
};

export const listEmployees = () =>
  v4APICall('/employee/list', {
    loading: false
  });

export const getJobList = () =>
  v4APICall('/employee/joblist', {
    loading: false
  });

export const addEmployee = employee =>
  v4APICall('/employee', { method: 'post', data: employee, error: true });

export const updateEmployee = employee =>
  v4APICall(`/employee/${employee._id}`, {
    method: 'put',
    data: employee,
    error: true
  });

export const importEmployees = sourceUrl =>
  v4APICall('/employee/upload', { method: 'post', data: { sourceUrl } });

export const fetchEmployee = id => v4APICall(`/employee/${id}`);

export const deleteEmployee = id =>
  v4APICall(`/employee/${id}`, { method: 'delete', toast: true });

export const resendWelcomeNotification = id =>
  v4APICall(`/employee/${id}/resendWelcomeNotification`, {
    toast: true,
    method: 'post',
    hierarchy: 'company'
  });

export const resetPassword = id =>
  v4APICall(`/employee/${id}/resetPassword`, { toast: true, method: 'post' });

export const updatePassword = (userId, password) =>
  v4APICall(`/employee/${userId}/updatePassword`, {
    hierarchy: false,
    method: 'put',
    data: password,
    error: true
  });

export const fetchEmployeeQualifications = (id, type = 'all') =>
  v4APICall(`/employee/${id}/qualifications/${type}`);

export const fetchEmployeeIncidents = (id, type = 'all') =>
  v4APICall(`/employee/${id}/incidents/${type}`);

export const fetchEmployeeTrainings = (id, type = 'all') =>
  v4APICall(`/employee/${id}/trainings/${type}`);

export const fetchEmployeeInteractions = id =>
  v4APICall(`/employee/${id}/interactions`);

export const fetchEmployeeBravos = id => v4APICall(`/employee/${id}/bravos`);

export const fetchCompanyUsers = () =>
  v4APICall('/employee/info', {
    loading: false,
    hierarchy: 'company',
    method: 'post'
  });

export const fetchAllUsers = () =>
  v4APICall('/employee/info', {
    loading: false,
    hierarchy: 'employeeHierarchy',
    method: 'post'
  });

export const fetchAllUsersForLocation = (locationId, data) =>
  v4APICall(`${locationId}/employee/info`, {
    hierarchy: false,
    loading: false,
    method: 'post',
    data
  });
export const fetchUser = id =>
  v4APICall(`/employee/${id}/info`, { loading: false, hierarchy: false });

export const loadDashboard = () => {
  const state = store.getState();
  const id = getLoggedInUser(state)._id;
  return v4APICall(`/employee/${id}/dashboard`, { loading: false });
};
export const getCards = () =>
  v4APICall(`/employee/dashboard/cards`, { loading: false });
export const getCard = payload =>
  v4APICall(`/employee/dashboard/cards/card`, {
    method: 'post',
    data: { type: payload.type, timeFrame: payload.timeFrame },
    loading: false
  });

export const customizeHomepage = cardId =>
  v4APICall(`/employee/customHomepage`, {
    method: 'put',
    data: { cardId: cardId },
    loading: false,
    toast: true
  });

export const fetchCustomHomepageCards = () =>
  v4APICall('/employee/customHomepage');

export const setIncidentOwner = (locationId, employeeId) =>
  v4APICall(`${locationId}/setOwner/${employeeId}`, {
    hierarchy: false,
    loading: false,
    method: 'put'
  });

export const fetchQrCodeSheet = ids =>
  v4APICall(`/employee/createQRCodeSheet/`, { data: { ids }, method: 'post' });

export const exportEmployeesToCSV = options =>
  v4APICall(`/employee/exportEmployeesToCSV`);

export const fetchSafetyAuditTemplates = (type = 'safety') =>
  v4APICall(`/safetyAudit/templates/${type}`, {
    hierarchy: 'company',
    loading: true
  });
export const fetchSafetyAuditTemplateById = (id, type = 'safety') =>
  v4APICall(`/safetyAudit/templates/${type}/${id}`);
export const createSafetyAuditTemplate = template =>
  v4APICall('/safetyAudit/templates', {
    method: 'post',
    data: template,
    error: true
  });
export const updateSafetyAuditTemplate = template =>
  v4APICall(`/safetyAudit/templates/${template._id}`, {
    method: 'put',
    data: template
  });
export const deleteSafetyAuditTemplate = template =>
  v4APICall(`/safetyAudit/templates/${template._id}`, {
    method: 'delete'
  });

export const fetchSafetyAuditRepeatables = (type = 'safety') =>
  v4APICall(`/repeatable/type/${type}Audits`);
export const fetchSafetyAuditRepeatableById = id =>
  v4APICall(`/repeatable/${id}`);
export const createSafetyAuditRepeatable = repeatable =>
  v4APICall(`/repeatable`, {
    method: 'post',
    data: repeatable,
    toast: true
  });
export const updateSafetyAuditRepeatable = repeatable =>
  v4APICall(`/repeatable/${repeatable._id}`, {
    method: 'put',
    data: repeatable
  });
export const deleteSafetyAuditRepeatable = repeatable =>
  v4APICall(`/repeatable/${repeatable._id}`, {
    method: 'delete'
  });

export const fetchSafetyAuditScanOnDemands = (type = 'safety') =>
  v4APICall(`/scanOnDemand/type/${type}Audits`);
export const fetchSafetyAuditScanOnDemandById = id =>
  v4APICall(`/scanOnDemand/${id}`);
export const createSafetyAuditScanOnDemand = scanOnDemand =>
  v4APICall(`/scanOnDemand`, {
    method: 'post',
    toast: true,
    data: scanOnDemand
  });
export const updateSafetyAuditScanOnDemand = repeatable =>
  v4APICall(`/scanOnDemand/${repeatable._id}`, {
    method: 'put',
    data: repeatable
  });
export const deleteSafetyAuditScanOnDemand = repeatable =>
  v4APICall(`/scanOnDemand/${repeatable._id}`, {
    method: 'delete'
  });

export const fetchSafetyAudits = (type = 'safety', data) =>
  v4APICall(`/safetyAudit/list/${type}`, {
    loading: false,
    method: 'post',
    data
  });
export const fetchSafetyAuditById = id => v4APICall(`/safetyAudit/${id}`);
export const createSafetyAudit = audit =>
  v4APICall('/safetyAudit', { method: 'post', data: audit });
export const updateSafetyAudit = audit =>
  v4APICall(`/safetyAudit/${audit._id}`, {
    method: 'put',
    data: audit
  });
export const updateSafetyAuditStage = audit =>
  v4APICall(`/safetyAudit/${audit._id}/updateStage`, {
    method: 'put',
    data: { currentWfStage: audit.currentWfStage }
  });
export const updateSafetyAuditAdditionalNotes = audit =>
  v4APICall(`/safetyAudit/${audit._id}/updateAdditionalNotes`, {
    method: 'put',
    data: { additionalNotes: audit.additionalNotes }
  });
export const deleteSafetyAudit = audit =>
  v4APICall(`/safetyAudit/${audit._id}`, {
    method: 'delete'
  });
export const filterSafetyAuditSections = (auditId, filter) =>
  v4APICall(`/safetyAudit/${auditId}/filter`, { method: 'post', data: filter });
export const setSafetyAuditNumber = (safetyAuditId, safetyAuditNumber) =>
  v4APICall(
    `/safetyAudit/${safetyAuditId}/safetyAuditNumber/${safetyAuditNumber}`,
    { method: 'put', error: true, toast: true }
  );
export const reassignSafetyAudits = payload =>
  v4APICall(`/safetyAudit/reassignAudits`, {
    method: 'post',
    toast: true,
    error: true,
    data: payload
  });

export const sendAuditReminder = ids =>
  v4APICall('/safetyAudit/reminder', {
    method: 'post',
    toast: true,
    error: true,
    data: ids
  });

export const getAnalyticsDashboard = (dashboardId = '') =>
  v4APICall(`/analytics/dashboards/${dashboardId}`, { hierarchy: false });

export const createAnalyticsDashboard = data =>
  v4APICall(`/analytics/dashboards`, {
    method: 'post',
    data,
    hierarchy: false
  });
export const deleteAnalyticsDashboard = dashboardId =>
  v4APICall(`/analytics/dashboards/${dashboardId}`, {
    method: 'delete',
    hierarchy: false
  });
export const updateAnalyticsDashboard = (dashboardId = '', data) =>
  v4APICall(`/analytics/dashboards/${dashboardId}`, {
    method: 'put',
    data,
    hierarchy: false
  });
export const cloneAnalyticsDashboard = data =>
  v4APICall(`/analytics/dashboards/${data._id}/clone`, {
    method: 'post',
    hierarchy: false,
    data
  });
export const setDashboardFavorite = (id, fav = true) =>
  v4APICall(`/analytics/dashboards/${id}/favorite/${fav}`, {
    method: 'put',
    hierarchy: false
  });

export const createAnalyticsChart = (dashboardId, data) =>
  v4APICall(`/analytics/charts/${dashboardId}`, {
    method: 'post',
    hierarchy: false,
    data
  });
export const deleteAnalyticsChart = chartId =>
  v4APICall(`/analytics/charts/${chartId}`, {
    method: 'delete',
    hierarchy: false
  });
export const updateAnalyticsChart = (chartId, data) =>
  v4APICall(`/analytics/charts/${chartId}`, {
    method: 'put',
    data,
    hierarchy: false
  });
export const fetchAnalyticsChart = chartId =>
  v4APICall(`/analytics/charts/${chartId}`, { hierarchy: false });

export const fetchAnalyticsDatasources = () =>
  v4APICall('/analytics/datasources', { hierarchy: 'company' });
export const getAllAnalyticsDatapoints = () =>
  v4APICall('/analytics/datapoints', { hierarchy: 'company' });
export const fetchAnalyticsDatapoints = datasource =>
  v4APICall(`/analytics/${datasource}/datapoints`);
export const fetchAnalytics = data =>
  v4APICall(`/analytics/preview`, { method: 'post', data, loading: false });
export const fetchColumnSettings = () => v4APICall(`/columnSetting/`);
export const updateColumnSettings = data =>
  v4APICall(`/columnSetting/`, {
    method: 'put',
    data,
    toast: true
  });

export const exportAnalytics = data =>
  v4APICall('/analytics/export', { method: 'post', data });

// incidents
export const fetchHasLegacyObservations = () =>
  v4APICall(`/report/legacyObservations`, {
    loading: false
  });

export const listReports = data =>
  v4APICall(`/report/list`, {
    loading: false,
    method: 'post',
    data
  });

export const listAllReports = data =>
  v4APICall(`/incidents/webList`, {
    loading: true,
    method: 'post',
    data
  });

export const reportBreakdown = data =>
  v4APICall(`/incidents/breakdown`, {
    loading: true,
    method: 'post',
    data
  });

export const updateActivityAttachments = attachment =>
  v4APICall(`/incidents/updateIncidentActivity`, {
    method: 'post',
    data: attachment
  });

export const emailInitialReport = payload => {
  return v4APICall(`/incidents/${payload.incidentId}/emailInitialReport`, {
    method: 'put',
    data: payload,
    toast: true,
    error: true
  });
};

export const emailAssignees = payload => {
  return v4APICall(`/incidents/emailAssignee`, {
    method: 'put',
    data: payload,
    toast: true,
    error: true
  });
};

export const fetchIncidentById = id =>
  v4APICall(`/incidents/${id}`, {
    hierarchy: 'company'
  });

export const createIncident = payload =>
  v4APICall(`/incidents`, {
    hierarchy: 'company',
    data: payload,
    method: 'post'
  });

export const populateOshaForms = incidentId =>
  v4APICall(`/oshaForms/populate/?incidentId=${incidentId}`, {
    hierarchy: 'company'
  });

export const fetchInitialReportList = () =>
  v4APICall(`/initialReport/userReportList`);

export const fetchInitialReportById = id => v4APICall(`/initialReport/${id}`);

export const createInitialReport = payload =>
  v4APICall(`/initialReport`, {
    data: payload,
    method: 'post',
    hierarchy: 'company'
  });

export const updateInitialReport = payload =>
  v4APICall(`/initialReport/${payload._id}`, {
    data: payload,
    method: 'put'
  });

export const deleteInitialReport = payload =>
  v4APICall(`/initialReport/${payload}`, {
    method: 'delete'
  });

export const deleteManyInitialReports = ids =>
  v4APICall(`/initialReport/deleteMany`, {
    method: 'put',
    data: { ids },
    toast: true
  });

export const updateReportComponent = payload =>
  v4APICall(`/incidents/${payload.incidentId}/updateReportComponent`, {
    data: payload.activeReportComponent,
    method: 'post'
  });

export const updateIncidentPermissions = (incidentId, permissions) =>
  v4APICall(`/incidents/${incidentId}/permissions`, {
    data: permissions,
    method: 'put'
  });

export const fetchIncidentBasics = () => v4APICall(`/fieldGroups`);

export const fetchReportBasics = reportLabel =>
  v4APICall(`/fieldGroups/${reportLabel}`);

export const findIncidentQuestionsByReportType = reportTypes =>
  v4APICall(`/fieldGroups/reportTypes`, {
    data: reportTypes,
    method: 'post'
  });

export const updateIncidentBasics = (
  incidentId,
  answers,
  signature,
  additionalSignatures
) =>
  v4APICall(`/incidents/${incidentId}/basics`, {
    data: {
      answers,
      signature,
      additionalSignatures
    },
    method: 'put'
  });

export const updateIncidentNumber = payload =>
  v4APICall(`/incidents/${payload.incidentId}/incidentNumber`, {
    data: payload,
    method: 'put'
  });
export const updateIncidentOwner = data =>
  v4APICall(`/incidents/${data.incidentId}/incidentOwner`, {
    data,
    method: 'put'
  });

export const updateIncidentStage = (incidentId, reportStage) =>
  v4APICall(`/incidents/${incidentId}/incidentStage`, {
    data: reportStage,
    method: 'put'
  });

export const deleteIncident = incidentId =>
  v4APICall(`/incidents/${incidentId}`, {
    method: 'delete'
  });

export const printReport = data =>
  v4APICall(`/incidents/${data.incidentId}/printReport`, {
    data,
    method: 'post',
    error: true
  });

export const updateReportTypes = (incidentId, data) =>
  v4APICall(`/incidents/${incidentId}/newReportTypes`, {
    data,
    method: 'put'
  });

export const archiveIncident = data =>
  v4APICall(`/incidents/archive`, {
    data,
    method: 'put',
    toast: true
  });

export const archiveIncidents = data =>
  v4APICall(`/incidents/archiveMany`, {
    data,
    method: 'put',
    toast: true
  });

//trainings
export const fetchAllTrainings = (documentFolderId, data) =>
  v4APICall(`/trainings/list/${documentFolderId}`, {
    loading: true,
    method: 'post',
    data
  });

export const fetchCustomTrainingTemplates = () =>
  v4APICall('/trainingTemplates/list', {
    hierarchy: true
  });
export const getTrainingTemplateById = id =>
  v4APICall(`/trainingTemplates/${id}`, {
    hierarchy: true
  });

export const addCustomTrainingTemplate = template =>
  v4APICall('/trainingTemplates', {
    method: 'post',
    data: template,
    error: true
  });

export const updateCustomTrainingTemplate = template =>
  v4APICall(`/trainingTemplates/${template._id}`, {
    method: 'put',
    data: template,
    error: true
  });

export const deleteCustomTrainingTemplate = template =>
  v4APICall(`/trainingTemplates/${template._id}`, {
    method: 'delete',
    error: true,
    toast: true
  });

export const createTraining = training =>
  v4APICall('/trainings', {
    method: 'post',
    data: training,
    error: true,
    toast: true
  });

export const fetchTrainingById = id => v4APICall(`/trainings/${id}`);

export const updateChildTraining = training =>
  v4APICall(`/trainings/child/${training._id}`, {
    method: 'put',
    toast: true,
    data: training
  });

export const updateInPersonTraining = training =>
  v4APICall(`/trainings/inperson/${training._id}`, {
    method: 'put',
    data: training,
    error: true,
    toast: true
  });
export const approveTrainings = payload =>
  v4APICall(`/trainings/approve/${payload.masterTraining._id}`, {
    method: 'put',
    data: payload
  });

export const updateTraining = training =>
  v4APICall(`/trainings/${training._id}`, {
    method: 'put',
    data: training
  });

export const fetchPerformTraining = (id, assignee) =>
  v4APICall(`/trainings/${id}/${assignee}`);

export const deleteTraining = training =>
  v4APICall(`/trainings/${training._id}`, {
    method: 'delete'
  });

export const sendTrainingReminder = ids =>
  v4APICall('/trainings/reminder', {
    method: 'post',
    toast: true,
    error: true,
    data: ids
  });

export const checkJobTitleGroupPairs = data =>
  v4APICall('/trainings/checkPairs', {
    method: 'post',
    error: true,
    data
  });

export const sendCourseReminder = ids =>
  v4APICall('/trainingCourses/reminder', {
    method: 'post',
    toast: true,
    error: true,
    data: ids
  });

export const printTrainingSummary = id => v4APICall(`/trainings/${id}/print`);

export const createTrainingRepeatable = repeatable =>
  v4APICall(`/repeatable`, {
    method: 'post',
    data: { ...repeatable, type: 'trainings' },
    toast: true
  });

export const updateTrainingRepeatable = repeatable =>
  v4APICall(`/repeatable/${repeatable._id}`, {
    method: 'put',
    data: repeatable
  });

export const fetchTrainingRepeatables = documentFolderId =>
  v4APICall(`/repeatable/type/trainings/${documentFolderId}`);

export const fetchTrainingRepeatableById = id =>
  v4APICall(`/repeatable/${id}`, { data: { type: 'trainings' } });

export const deleteTrainingRepeatable = repeatable =>
  v4APICall(`/repeatable/${repeatable._id}`, {
    method: 'delete',
    toast: true,
    error: true
  });

export const fetchTrainingScanOnDemands = documentFolderId =>
  v4APICall(`/scanOnDemand/type/trainings/${documentFolderId}`);

export const fetchTrainingScanOnDemandById = id =>
  v4APICall(`/scanOnDemand/${id}`);

export const createTrainingScanOnDemand = repeatable =>
  v4APICall(`/scanOnDemand`, {
    method: 'post',
    toast: true,
    data: { ...repeatable, type: 'trainings' }
  });

export const updateTrainingScanOnDemand = repeatable =>
  v4APICall(`/scanOnDemand/${repeatable._id}`, {
    method: 'put',
    data: repeatable
  });

export const deleteTrainingScanOnDemand = repeatable =>
  v4APICall(`/scanOnDemand/${repeatable._id}`, {
    method: 'delete',
    toast: true,
    error: true
  });

export const getUserApprovedTrainings = (userId, data) =>
  v4APICall(`/trainings/approved/${userId}`, {
    loading: false,
    method: 'post',
    data
  });

export const getUserNonApprovedTrainings = userId =>
  v4APICall(`/trainings/nonApproved/${userId}`, { loading: false });

export const downloadUserTrainings = userId =>
  v4APICall(`/trainings/download/${userId}`, {
    method: 'post',
    error: true
  });

export const downloadCerts = id =>
  v4APICall(`/trainings/certs/${id}`, {
    method: 'post',
    error: true
  });

export const importHistoricalTrainings = sourceUrl =>
  v4APICall(`/trainings/upload/false`, {
    method: 'post',
    data: { sourceUrl },
    hierarchy: 'company'
  });

export const getCourseTemplates = () =>
  v4APICall(`/courseTemplates/`, {
    hierarchy: true
  });
export const getCourseTemplateById = id =>
  v4APICall(`/courseTemplates/${id}`, {
    hierarchy: true
  });

export const createCourseTemplate = template =>
  v4APICall('/courseTemplates', {
    method: 'post',
    data: template,
    error: true
  });

export const updateCourseTemplate = template =>
  v4APICall(`/courseTemplates/${template._id}`, {
    method: 'put',
    data: template,
    error: true
  });

export const deleteCourseTemplate = template =>
  v4APICall(`/courseTemplates/${template._id}`, {
    method: 'delete',
    toast: true,
    error: true
  });
export const getTrainingCourses = (folderId = '', data) =>
  v4APICall(`/trainingCourses/list/${folderId}`, {
    hierarchy: true,
    loading: true,
    method: 'post',
    data
  });
export const getTrainingCourseById = (id, userId = '') =>
  v4APICall(`/trainingCourses/${id}/${userId}`, {
    hierarchy: true
  });

export const createTrainingCourse = course =>
  v4APICall('/trainingCourses', {
    method: 'post',
    data: course,
    error: true,
    toast: true
  });

export const getUserCompletedCourses = (userId, data) =>
  v4APICall(`/trainingCourses/completed/${userId}`, {
    loading: false,
    method: 'post',
    data
  });

export const updateTrainingCourse = course =>
  v4APICall(`/trainingCourses/${course._id}`, {
    method: 'put',
    data: course
  });

export const updateTrainingCoursesFolder = course =>
  v4APICall(`/trainingCourses/update/folder`, {
    method: 'put',
    data: course,
    error: true
  });

export const deleteTrainingCourse = id =>
  v4APICall(`/trainingCourses/${id}`, {
    method: 'delete',
    error: true,
    toast: true
  });
// quizzes
export const fetchQuizzes = () =>
  v4APICall('/quizTemplates', { hierarchy: 'company', error: true });

export const fetchQuizById = id =>
  v4APICall(`/quizTemplates/${id}`, { error: true });

export const createQuiz = payload =>
  v4APICall(`/quizTemplates`, {
    method: 'post',
    data: payload,
    hierarchy: 'company',
    toast: true,
    error: true
  });

export const updateQuiz = payload =>
  v4APICall(`/quizTemplates/${payload._id}`, {
    method: 'put',
    data: payload,
    toast: true,
    error: true
  });

export const deleteQuiz = id =>
  v4APICall(`/quizTemplates/${id}`, {
    method: 'delete',
    toast: true,
    error: true
  });

export const submitQuiz = payload =>
  v4APICall(`/trainings/${payload._id}/submitQuiz`, {
    method: 'put',
    data: payload
  });

export const doesQuizHaveTitle = title =>
  v4APICall(`/quizTemplates/doesTitleExist/${title}`, {
    hierarchy: 'company',
    loading: false
  });

//osha
export const fetchOshaLogs = year => v4APICall(`/oshaLogs/fetch/${year}`);

export const createOshaLog = data =>
  v4APICall('/oshaLogs/', {
    method: 'post',
    data
  });

export const updateOshaLog = data =>
  v4APICall('/oshaLogs/update', {
    method: 'post',
    data
  });

export const createOshaQuestions = data =>
  v4APICall('/oshaQuestions/', {
    method: 'post',
    data
  });

export const updateOshaQuestions = data =>
  v4APICall(`/oshaQuestions/${data._id}`, {
    method: 'put',
    data
  });

export const printExcel300 = year =>
  v4APICall(`/oshaLogs/printExcel300/${year}`);

export const printExcel300A = payload =>
  v4APICall(`/oshaLogs/printExcel300A/${payload.year}`, {
    method: 'post',
    data: payload
  });

export const printExcel301 = year =>
  v4APICall(`/oshaLogs/printExcel301/${year}`);

export const printCSV = (year, type) =>
  v4APICall(`/oshaLogs/printCSV/${year}`, { method: 'post', data: { type } });

export const singlePrintExcel301 = incidentId =>
  v4APICall(`/oshaLogs/singlePrintExcel301/${incidentId}`);

export const generateHeadcountTemplate = payload =>
  v4APICall(`/oshaLogs/generateHeadcount/`, {
    method: 'post',
    data: payload
  });

export const generateHoursTemplate = payload =>
  v4APICall(`/oshaLogs/generateHours/`, {
    method: 'post',
    data: payload
  });

export const uploadOshaCsv = (payload, type, year, level) =>
  v4APICall(`/oshaLogs/upload/`, {
    toast: true,
    method: 'post',
    data: payload
  });

// attachments
export const uploadAttachment = (data, options) =>
  v4APICall(`/attachments/?${getQueryString(options)}`, {
    method: 'post',
    data,
    error: true,
    transformRequest: data => data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
export const uploadCompanyLogo = (attachments, options) =>
  v4APICall(`/attachments/?${getQueryString(options)}`, {
    method: 'post',
    data: attachments,
    hierarchy: false
  });
export const getAttachmentById = id => v4APICall(`/attachments/${id}`);

export const updateAttachmentLogo = payload =>
  v4APICall(`/attachments/${payload._id}`, {
    method: 'put',
    data: payload,
    hierarchy: false
  });
export const updateAttachment = payload =>
  v4APICall(`/attachments/${payload._id}`, {
    method: 'put',
    data: payload
  });

export const duplicateAttachments = payload =>
  v4APICall(`/attachments/duplicate`, {
    method: 'post',
    data: payload
  });

export const fetchAttachmentsByIds = ids =>
  v4APICall(`/attachments/getAttachmentsByIds/${ids}`);

export const updateManyAttachments = payload =>
  v4APICall(`/attachments/updateMany`, {
    method: 'put',
    data: payload
  });

export const fetchAttachmentByOwnerId = (ownerId, ownerType) =>
  v4APICall(`/attachments/fetchByOwner/${ownerType}/${ownerId}`);

export const deleteAttachment = (id, fromOnTheSpot = false) =>
  v4APICall(`/attachments/${id}/${fromOnTheSpot}`, {
    method: 'delete'
  });

export const deleteManyAttachments = ids =>
  v4APICall(`/attachments/deleteMany`, {
    method: 'delete',
    data: { attachmentIds: ids }
  });

export const importReports = (sourceUrl, reportType) =>
  v4APICall('/incidents/upload', {
    method: 'post',
    data: { sourceUrl, reportType },
    hierarchy: 'company'
  });

//tasks
export const addTask = task =>
  v4APICall('/tasks', { method: 'post', data: task });

export const createTaskRepeatable = repeatable =>
  v4APICall(`/repeatable`, {
    method: 'post',
    data: { ...repeatable, type: 'tasks' },
    toast: true
  });

export const updateTaskRepeatable = repeatable =>
  v4APICall(`/repeatable/${repeatable._id}`, {
    method: 'put',
    data: repeatable
  });

export const deleteTaskRepeatable = repeatable =>
  v4APICall(`/repeatable/${repeatable._id}`, {
    method: 'delete'
  });

export const fetchTaskById = id => v4APICall(`/tasks/${id}`);

export const fetchTaskByOwnerId = id => v4APICall(`/tasks/ownedBy/${id}`);

export const fetchTasks = (type, data) =>
  v4APICall(`/tasks/list/${type}`, {
    loading: true,
    method: 'post',
    data
  });

export const fetchRepeatingTasks = () => v4APICall('/repeatable/type/tasks');

export const fetchTaskRepeatableById = id =>
  v4APICall(`/repeatable/${id}`, { data: { type: 'tasks' } });

export const fetchCreatedByTasks = userId =>
  v4APICall(`/tasks/createdBy/${userId}`);

export const updateTask = task =>
  v4APICall(`/tasks/${task._id}`, {
    method: 'put',
    data: task
  });

export const deleteTask = task =>
  v4APICall(`/tasks/${task._id}`, {
    method: 'delete'
  });

export const reassignTasks = payload =>
  v4APICall(`/tasks/reassignTasks`, {
    method: 'post',
    toast: true,
    error: true,
    data: payload
  });

export const sendTaskReminder = ids =>
  v4APICall('/tasks/reminder', {
    method: 'post',
    toast: true,
    error: true,
    data: ids
  });

//reminder
export const sendReminder = (assignmentId, assignmentType) =>
  v4APICall(`/tasks/reminder/${assignmentType}/${assignmentId}`, {
    method: 'post',
    hierarchy: 'company'
  });

// comments
export const fetchCommentById = id => v4APICall(`/comment/${id}`);

export const fetchTaskComments = taskId => v4APICall(`/comment/${taskId}`);

export const updateComment = comment =>
  v4APICall(`/comment/${comment._id}`, {
    method: 'put',
    data: comment
  });

export const deleteComment = comment =>
  v4APICall(`/comment/${comment._id}`, {
    method: 'delete'
  });

export const addComment = comment =>
  v4APICall('/comment', { method: 'post', data: comment });

//covid
export const fetchCovidEmployees = () =>
  v4APICall('/covidTracking/list', { loading: false });

export const fetchEmployeeCovidInfo = id =>
  v4APICall(`/covidTracking/employeeStatus/${id}`);

export const generateExceptionList = data =>
  v4APICall('/covidTracking/generateExceptionList', {
    method: 'post',
    data
  });

export const fetchCovidExposureList = () =>
  v4APICall('/covidTracking/exposure');

export const fetchCovidExposureById = id =>
  v4APICall(`/covidTracking/exposure/${id}`);

export const addTestResult = (employeeId, testResults) =>
  v4APICall(`/covidTracking/addTestResult/${employeeId}`, {
    method: 'post',
    data: testResults
  });

export const updateEmployeeVaccinationInfo = (id, data) =>
  v4APICall(`/covidTracking/updateVaccinationInfo/${id}`, {
    method: 'post',
    data
  });

export const addExposure = data =>
  v4APICall('/covidTracking/addExposure', {
    method: 'post',
    data
  });

export const updateExposure = (id, data) =>
  v4APICall(`/covidTracking/updateExposure/${id}`, {
    method: 'post',
    data
  });

export const sendCovidExposureNotification = data =>
  v4APICall(`/covidTracking/sendExposureNotification`, {
    method: 'post',
    data
  });

export const downloadVaccinatedEmployees = () =>
  v4APICall(`/covidTracking/downloadVaccinatedEmployees`, {
    method: 'post'
  });

export const downloadNonVaccinatedEmployees = () =>
  v4APICall(`/covidTracking/downloadNonVaccinatedEmployees`, {
    method: 'post'
  });

export const downloadCovidExposures = data =>
  v4APICall(`/covidTracking/downloadCovidExposure`, {
    method: 'post',
    data
  });

// notifications
export const getUserNotifications = id =>
  v4APICall(`/notifications/user/${id}`, {
    hierarchy: false
  });
export const getCompanyNotifications = (id, newNotifs = '') =>
  v4APICall(`/notifications/company/${id}/${newNotifs}`, {
    hierarchy: false
  });
export const updateCompanyNotifications = data =>
  v4APICall(`/notifications/company/${data.companyId}`, {
    method: 'put',
    data,
    toast: true
  });

// documents
export const fetchEmployeeDocuments = (empId, data) =>
  v4APICall(`/documents/empDocuments/${empId}`, {
    hierarchy: 'company',
    loading: false,
    method: 'post',
    data
  });

export const fetchDocumentsByType = (documentType, documentFolderId, payload) =>
  v4APICall(`/documents/list/${documentType}/${documentFolderId}`, {
    hierarchy: true,
    method: 'post',
    loading: true,
    data: payload
  });

export const fetchQualificationType = () =>
  v4APICall(`/documents/getQualificationCategories`, {
    hierarchy: 'company'
  });

export const createDocument = data =>
  v4APICall(`/documents`, {
    method: 'post',
    data
  });

export const updateDocument = payload =>
  v4APICall(`/documents/${payload._id}`, {
    method: 'put',
    data: payload,
    toast: true
  });

export const deleteDocument = id =>
  v4APICall(`/documents/${id}`, {
    method: 'delete',
    toast: true
  });

export const printCustomDoc = payload =>
  v4APICall(`/documents/printCustomDoc/${payload._id}`, {
    data: payload
  });

export const fetchDocumentById = id => v4APICall(`/documents/${id}`);
export const createDocumentQRCodeSheet = ids =>
  v4APICall('/documents/createQRCodeSheet', {
    hierarchy: 'company',
    method: 'post',
    data: { ids }
  });

export const getDistinctCustomDocTitles = () =>
  v4APICall('/documents/customDocTitles', {
    hierarchy: true
  });

export const updateManyDocuments = data =>
  v4APICall(`/documents/updateMany`, {
    method: 'put',
    data
  });

export const deleteManyDocuments = ids =>
  v4APICall(`/documents/deleteMany`, {
    method: 'delete',
    data: ids
  });

export const downloadUserQualifications = (userId, documentIds) =>
  v4APICall(`/documents/download/${userId}`, {
    method: 'post',
    data: documentIds,
    error: true
  });

// notes
export const addNote = data =>
  v4APICall(`/notes`, {
    method: 'post',
    data
  });

export const updateNote = payload =>
  v4APICall(`/notes/${payload._id}`, {
    method: 'put',
    data: payload
  });

export const updateNotes = payload =>
  v4APICall(`/notes/update`, {
    method: 'put',
    data: payload
  });

export const deleteNoteById = payload =>
  v4APICall(`/notes/${payload.note._id}`, {
    method: 'delete',
    data: payload
  });

// equipment
export const fetchEquipmentListAll = payload =>
  v4APICall('/equipment/list', { method: 'post', data: payload });

export const fetchEquipmentTitles = payload =>
  v4APICall('/equipment/titles', {
    method: 'post',
    data: payload
  });

export const fetchEquipmentById = equipmentId =>
  v4APICall(`/equipment/fetch/${equipmentId}`);

export const createEquipment = payload =>
  v4APICall('/equipment/create', {
    method: 'post',
    data: payload,
    error: true
  });

export const updateEquipmentById = payload =>
  v4APICall(`/equipment/update/${payload._id}`, {
    method: 'put',
    data: payload,
    error: true
  });

export const deleteEquipment = equipment =>
  v4APICall(`/equipment/${equipment._id}`, {
    method: 'delete',
    data: equipment
  });

export const updateEquipmentStage = payload =>
  v4APICall(`/equipment/updateStatus/${payload._id}`, {
    method: 'put',
    data: payload
  });

export const uploadEquipment = files =>
  v4APICall('/equipment/upload', { method: 'post', data: { files } });

export const updateEquipmentRequired = payload =>
  v4APICall(`/equipment/updateRequired/${payload._id}`, {
    method: 'put',
    data: payload
  });

export const downloadEquipmentQrCodes = payload =>
  v4APICall(`/equipment/downloadQrCodes`, {
    method: 'post',
    data: payload,
    error: true
  });
// safety walks
export const fetchSafetyWalks = data =>
  v4APICall('/safetyWalks/listAllSw', {
    hierarchy: true,
    loading: true,
    method: 'post',
    data
  });

export const fetchSafetyWalkById = id => v4APICall(`/safetyWalks/${id}`);

export const createSafetyWalk = data =>
  v4APICall(`/safetyWalks`, {
    method: 'post',
    data
  });

export const updateSafetyWalk = data =>
  v4APICall(`/safetyWalks/${data._id}`, {
    method: 'put',
    data
  });

export const setSafetyWalkNumber = (safetyWalkId, safetyWalkNumber) =>
  v4APICall(`/safetyWalks/updateSafetyWalkNumber/${safetyWalkId}`, {
    method: 'put',
    data: { safetyWalkNumber }
  });

export const deleteSafetyWalk = id =>
  v4APICall(`/safetyWalks/${id}`, {
    method: 'delete',
    toast: true,
    error: true
  });

export const printSafetyWalk = id => v4APICall(`/safetyWalks/print/${id}`);

export const downloadSafetyWalkQrCode = () =>
  v4APICall('/safetyWalks/downloadQrCode', {
    hierarchy: true,
    loading: true,
    method: 'post',
    responseType: 'blob'
  });

// continuous care
export const fetchContinuousCareByEmployeeId = (employeeId, data) =>
  v4APICall(`/continuousCares/list/${employeeId}`, {
    hierarchy: 'company',
    loading: false,
    method: 'post',
    data
  });

export const fetchContinuousCareByHrRepId = employeeId =>
  v4APICall(`/continuousCares/list/${employeeId}/&isApprovedByHr=${false}`, {
    hierarchy: 'company'
  });

export const fetchContinuousCareBySupervisorId = employeeId =>
  v4APICall(
    `/continuousCares/list/${employeeId}/&isApprovedBySupervisor=${false}`,
    {
      hierarchy: 'company'
    }
  );

export const fetchContinuousCareById = id =>
  v4APICall(`/continuousCares/${id}`, {
    hierarchy: 'company'
  });

export const createContinuousCare = data =>
  v4APICall(`/continuousCares`, {
    method: 'post',
    data
  });

export const getManyContinuousCare = data =>
  v4APICall(`/continuousCares/getMany`, {
    method: 'post',
    data
  });

export const updateContinuousCare = data =>
  v4APICall(`/continuousCares/${data._id}`, {
    method: 'put',
    data
  });

export const printContinuousCare = id =>
  v4APICall(`/continuousCares/print/${id}`, {
    hierarchy: 'company'
  });

export const deleteContinuousCare = id =>
  v4APICall(`/continuousCares/${id}`, {
    method: 'delete',
    hierarchy: 'company'
  });

// custom report questions
export const fetchCustomReportQuestions = (
  reportTemplateNumber,
  reportComponentName
) =>
  v4APICall(`/customReportQuestions/fetchQuestions`, {
    hierarchy: 'company',
    data: { reportTemplateNumber, reportComponentName },
    method: 'post'
  });

export const createCustomReportQuestions = data =>
  v4APICall(`/customReportQuestions`, {
    method: 'post',
    data
  });

export const updateCustomReportQuestions = data =>
  v4APICall(`/customReportQuestions/${data._id}`, {
    method: 'put',
    data
  });

export const deleteCustomReportQuestions = data =>
  v4APICall(`/customReportQuestions/${data._id}`, {
    method: 'delete',
    data
  });

export const shouldShowGeneral = () =>
  v4APICall(`/customReportQuestions/shouldShowGeneral`, {
    hierarchy: 'company',
    method: 'post'
  });

// custom doc templates
export const fetchCustomDocumentTemplates = () =>
  v4APICall(`/customDocuments`, {
    hierarchy: 'company'
  });

export const fetchCustomTemplate = id => v4APICall(`/customDocuments/${id}`);

export const createCustomDocumentTemplates = data =>
  v4APICall(`/customDocuments`, {
    method: 'post',
    data,
    toast: true,
    error: true
  });

export const updateCustomDocumentTemplates = data =>
  v4APICall(`/customDocuments/${data._id}`, {
    method: 'put',
    data,
    toast: true
  });

export const deleteCustomDocumentTemplates = id =>
  v4APICall(`/customDocuments/${id}`, {
    method: 'delete',
    toast: true
  });

export const createCustomDocumentQRCodeSheet = ids =>
  v4APICall('/customDocuments/createQRCodeSheet', {
    hierarchy: 'company',
    method: 'post',
    data: { ids }
  });

// claims
export const fetchClaims = () => v4APICall('/claim');
export const fetchClaimById = id => v4APICall(`/claim/${id}`);
export const createClaim = claim =>
  v4APICall('/claim/', {
    method: 'post',
    data: claim,
    hierarchy: 'company',
    error: true
  });
export const updateClaim = claim =>
  v4APICall(`/claim/${claim._id}`, {
    method: 'put',
    data: claim,
    hierarchy: 'company',
    error: true
  });
export const deleteClaim = claimId =>
  v4APICall(`/claim/${claimId}`, {
    method: 'delete',
    error: true
  });
export const fetchClaimPermissions = id =>
  v4APICall(`/claim/${id}/permissions`);
export const updateClaimPermisisons = (claimId, permissions) =>
  v4APICall(`/claim/${claimId}/permissions`, {
    method: 'put',
    data: permissions
  });
export const printClaim = claimId => v4APICall(`/claim/${claimId}/print`);

// witness statements
export const createWitnessStatement = ws =>
  v4APICall('/witnessStatements', {
    method: 'post',
    data: ws
  });
export const createExternalWitnessStatements = ws =>
  v4APICall('/witnessStatements/external', {
    method: 'post',
    data: ws
  });
export const resendWitnessStatement = id =>
  v4APICall(`/witnessStatements/reminder/external/${id}`);

export const fetchWitnessStatement = (id, needIncidentInfo) =>
  v4APICall(`/witnessStatements/${id}/${needIncidentInfo}`);

export const updateWitnessStatement = statement =>
  v4APICall(`/witnessStatements/${statement.id}`, {
    method: 'put',
    data: statement
  });

export const fetchWitnessStatementList = payload =>
  v4APICall('/witnessStatements/list', { method: 'post', data: payload });

export const sendWitnessStatementReminder = ids =>
  v4APICall('/witnessStatements/reminder/', {
    method: 'post',
    toast: true,
    data: ids,
    error: true
  });

// companies
export const addCompany = company =>
  v4APICall('/companies', {
    hierarchy: false,
    method: 'post',
    data: company
  });

export const updateCompany = company =>
  v4APICall(`/companies`, {
    hierarchy: 'company',
    method: 'put',
    data: company
  });

export const deleteCompany = company =>
  v4APICall(`/companies`, {
    hierarchy: 'company',
    method: 'delete',
    data: company
  });

export const getHoursWorked = year =>
  v4APICall(`/companies/hoursWorked/${year}`, {
    hierarchy: 'company'
  });

export const setHoursWorked = (year, hours, groupOrArea) =>
  v4APICall(`/companies/hoursWorked/${groupOrArea}/${year}`, {
    hierarchy: 'company',
    method: 'put',
    data: hours
  });

export const archiveMany = data =>
  v4APICall('/companies/archiveMany', {
    data,
    method: 'put',
    toast: true,
    error: true
  });

// groups
export const createGroup = group =>
  v4APICall('/groups', {
    hierarchy: false,
    method: 'post',
    data: group
  });

export const updateGroup = group =>
  v4APICall(`/groups/${group._id}`, {
    hierarchy: false,
    method: 'put',
    data: group
  });

export const deleteGroup = id =>
  v4APICall(`/groups/${id}`, {
    hierarchy: false,
    method: 'delete'
  });

//projects
export const addGroupProject = project =>
  v4APICall('/projects', {
    hierarchy: false,
    method: 'post',
    data: project
  });

export const updateGroupProject = project =>
  v4APICall(`/projects/${project._id}`, {
    hierarchy: false,
    method: 'put',
    data: project
  });

export const deleteGroupProject = id =>
  v4APICall(`/projects/${id}`, {
    hierarchy: false,
    method: 'delete'
  });

// Behavior Observations
export const fetchEmployeeObservations = id =>
  v4APICall(`/behaviorObservation/employee/${id}`);

export const fetchBehaviorObservations = data =>
  v4APICall(`/behaviorObservation`, {
    loading: false,
    method: 'post',
    data
  });

export const fetchBehaviorObservationById = id =>
  v4APICall(`/behaviorObservation/${id}`);

export const createBehaviorObservation = bo =>
  v4APICall('/behaviorObservation/create', {
    hierarchy: 'company',
    method: 'post',
    data: bo
  });

export const updateBehaviorObservation = bo =>
  v4APICall(`/behaviorObservation/${bo._id}`, {
    hierarchy: 'company',
    method: 'put',
    data: bo
  });

export const deleteBehaviorObservation = id =>
  v4APICall(`/behaviorObservation/${id}`, { method: 'delete' });

export const printBehaviorObservation = id =>
  v4APICall(`/behaviorObservation/${id}/print`, { hierarchy: false });

export const fetchBehaviorObservationTemplates = () =>
  v4APICall('/behaviorObservation/templates', {
    hierarchy: 'company'
  });

export const fetchBehaviorObservationTemplateById = id =>
  v4APICall(`/behaviorObservation/template/${id}`);

export const createBehaviorObservationTemplate = bot =>
  v4APICall('/behaviorObservation/template', {
    method: 'post',
    hierarchy: 'company',
    data: bot,
    error: true
  });

export const updateBehaviorObservationTemplate = bot =>
  v4APICall(`/behaviorObservation/template/${bot._id}`, {
    method: 'put',
    data: bot,
    error: true
  });

export const deleteBehaviorObservationTemplate = id =>
  v4APICall(`/behaviorObservation/template/${id}`, { method: 'delete' });

export const sendObservationReminder = ids =>
  v4APICall('/behaviorObservation/reminder', {
    method: 'post',
    toast: true,
    error: true,
    data: ids
  });

//Safety Meetings
export const fetchSafetyMeetings = data =>
  v4APICall(`/safetyMeeting/listSafetyMeetings`, {
    method: 'post',
    loading: true,
    data
  });

export const fetchSafetyMeetingById = id => v4APICall(`/safetyMeeting/${id}`);

export const createSafetyMeeting = meeting =>
  v4APICall('/safetyMeeting', {
    hierarchy: 'company',
    method: 'post',
    data: meeting
  });

export const updateSafetyMeeting = meeting =>
  v4APICall(`/safetyMeeting/${meeting._id}`, {
    hierarchy: 'company',
    method: 'put',
    data: meeting
  });

export const deleteSafetyMeeting = id =>
  v4APICall(`/safetyMeeting/${id}`, { method: 'delete', toast: true });

export const printSafetyMeeting = id => v4APICall(`/safetyMeeting/print/${id}`);

export const fetchSafetyMeetingTemplates = () =>
  v4APICall('/safetyMeeting/templates', {
    hierarchy: 'company'
  });

export const fetchSafetyMeetingTemplateById = id =>
  v4APICall(`/safetyMeeting/template/${id}`);

export const createSafetyMeetingTemplate = meeting =>
  v4APICall('/safetyMeeting/template', {
    method: 'post',
    hierarchy: 'company',
    data: meeting
  });

export const updateSafetyMeetingTemplate = meeting =>
  v4APICall(`/safetyMeeting/template/${meeting._id}`, {
    method: 'put',
    data: meeting
  });

export const deleteSafetyMeetingTemplate = id =>
  v4APICall(`/safetyMeeting/template/${id}`, { method: 'delete' });

// training exceptions
export const fetchTrainingExceptionTitles = data =>
  v4APICall(`/trainings/exception/trainingTitles`, {
    method: 'post',
    hierarchy: 'company',
    data
  });

export const fetchTrainingExceptions = data =>
  v4APICall(`/trainings/exception/list`, {
    method: 'post',
    data,
    error: true
  });

export const downloadExceptionList = data =>
  v4APICall(`/trainings/exception/download`, {
    method: 'post',
    data,
    error: true
  });

export const exportCustomReportQuestions = data =>
  v4APICall(`/customReportQuestions/export`, {
    method: 'post',
    data
  });

// leaderboard
export const fetchDashboards = data =>
  v4APICall(`/leaderboards/dashboards`, {
    hierarchy: 'company',
    data
  });

export const fetchDashboardById = (id, data) =>
  v4APICall(`/leaderboards/dashboard/${id}`, {
    hierarchy: 'company',
    method: 'post',
    data
  });

export const updateDashboardById = (id, data) =>
  v4APICall(`/leaderboards/updateDashboard/${id}`, {
    method: 'post',
    hierarchy: 'company',
    data
  });

export const createDashboard = data =>
  v4APICall(`/leaderboards/dashboard/create`, {
    method: 'post',
    hierarchy: 'company',
    data
  });

export const deleteDashboardById = id =>
  v4APICall(`/leaderboards/deleteDashboard/${id}`, {
    hierarchy: 'company',
    method: 'delete'
  });

export const fetchLeaderboardById = id =>
  v4APICall(`/leaderboards/${id}`, {
    hierarchy: 'company',
    method: 'post'
  });

export const createLeaderboard = data =>
  v4APICall(`/leaderboards`, {
    hierarchy: 'company',
    method: 'post',
    data
  });

export const updateLeaderboardById = (id, data) =>
  v4APICall(`/leaderboards/update/${id}`, {
    hierarchy: 'company',
    method: 'post',
    data
  });

export const deleteLeaderboardById = data =>
  v4APICall(`/leaderboards`, {
    hierarchy: 'company',
    method: 'delete',
    data
  });

export const downloadLeaderboard = data =>
  v4APICall(`/leaderboards/download`, {
    hierarchy: 'company',
    method: 'post',
    data
  });

export const updateManyRepeatables = data =>
  v4APICall(`/repeatable/updateMany`, {
    method: 'put',
    data: data
  });

// safety audit exceptions
export const fetchSafetyAuditExceptionTitles = data =>
  v4APICall(`/safetyAudit/exception/titles`, {
    method: 'post',
    hierarchy: 'company',
    data
  });
export const fetchFireExposures = (id, data) =>
  v4APICall(`/fireexposure/list/${id}`, {
    method: 'post',
    hierarchy: 'company',
    data
  });
export const fetchFireExposureById = id =>
  v4APICall(`/fireexposure/${id}`, {
    hierarchy: false
  });
export const createFireExposure = data =>
  v4APICall(`/fireexposure/create`, {
    data,
    method: 'post',
    hierarchy: 'company'
  });

export const fetchSafetyAuditExceptionReport = data =>
  v4APICall(`/safetyAudit/exception/list`, {
    method: 'post',
    data
  });

export const fetchEquipmentExceptionReport = data =>
  v4APICall(`/equipment/exception/list`, {
    method: 'post',
    data
  });

export const exportEquipmentExceptionReport = data =>
  v4APICall(`/equipment/exception/download`, {
    method: 'post',
    data
  });

export const sendSafetyAuditExceptionNotification = data =>
  v4APICall(`/safetyAudit/exception/email`, {
    method: 'post',
    data,
    toast: true,
    error: true
  });
export const downloadSafetyAuditExceptionList = data =>
  v4APICall(`/safetyAudit/exception/download`, {
    method: 'post',
    data
  });
export const deleteFireExposureById = id =>
  v4APICall(`/fireexposure/${id}`, {
    hierarchy: false,
    method: 'delete'
  });
export const printExposure = id => v4APICall(`/fireexposure/${id}/print`);

// document folders
export const fetchDocumentFoldersByType = documentType =>
  v4APICall(`/documentFolders/${documentType}`, {
    hierarchy: 'company'
  });

export const createDocumentFolder = payload =>
  v4APICall(`/documentFolders/`, {
    hierarchy: 'company',
    method: 'post',
    data: payload
  });

export const updateDocumentFolder = payload =>
  v4APICall(`/documentFolders/${payload._id}`, {
    hierarchy: 'company',
    data: payload,
    method: 'post'
  });

export const deleteDocumentFolder = folderId =>
  v4APICall(`/documentFolders/${folderId}`, {
    hierarchy: 'company',
    method: 'delete'
  });

// Announcements
export const fetchAnnouncementList = payload =>
  v4APICall(`/announcement`, {
    hierarchy: 'company',
    method: 'post',
    data: payload
  });

export const fetchEmployeeAnnouncement = id =>
  v4APICall(`/announcement/employee/${id}`, {
    hierarchy: 'company',
    loading: false
  });

export const fetchAnnouncementById = id =>
  v4APICall(`/announcement/${id}`, {
    hierarchy: 'company'
  });

export const createAnnouncement = payload =>
  v4APICall(`/announcement/create`, {
    hierarchy: 'company',
    method: 'post',
    data: payload,
    toast: true
  });

export const updateAnnouncementViewed = payload =>
  v4APICall(`/announcement/${payload._id}`, {
    hierarchy: 'company',
    data: payload,
    method: 'put'
  });

export const updateAnnouncement = payload =>
  v4APICall(`/announcement/update/${payload._id}`, {
    hierarchy: 'company',
    data: payload,
    method: 'put'
  });

export const resendAnnouncementNotification = id =>
  v4APICall(`/announcement/resend/${id}`, {
    hierarchy: 'company',
    method: 'post',
    toast: true
  });

export const deleteAnnouncement = id =>
  v4APICall(`/announcement/${id}`, {
    method: 'delete',
    toast: true
  });

// Safety Suggestions

export const fetchAllSafetySuggestions = data =>
  v4APICall(`/safetySuggestions/listWebSuggestions`, {
    loading: false,
    method: 'post',
    data
  });

export const fetchSafetySuggestion = id =>
  v4APICall(`/safetySuggestions/${id}`, {
    hierarchy: 'company'
  });

export const createSafetySuggestion = payload =>
  v4APICall(`/safetySuggestions`, {
    hierarchy: 'company',
    method: 'post',
    data: payload,
    error: true
  });

export const updateSafetySuggestion = payload =>
  v4APICall(`/safetySuggestions/${payload._id}`, {
    hierarchy: 'company',
    method: 'put',
    data: payload
  });

// userAccounts
export const loginUser = data =>
  v4APICall(`/userAccounts/login`, {
    hierarchy: false,
    method: 'post',
    data,
    error: true,
    allowNoToken: true
  });

export const userMicrosoftLogin = data =>
  v4APICall(`/userAccounts/microsoftLogin`, {
    hierarchy: false,
    method: 'post',
    data,
    error: true,
    allowNoToken: true
  });

export const fetchMicrosoftLoginConfig = () =>
  v4APICall(`/userAccounts/auth/sso`, {
    hierarchy: false,
    error: true,
    allowNoToken: true
  });

export const forgotPasswordReset = username =>
  v4APICall(`/userAccounts/resetPassword/${username}`, {
    hierarchy: false,
    method: 'post',
    error: true,
    allowNoToken: true
  });

export const validateSecurityQuestions = data =>
  v4APICall(`/userAccounts/resetPassword/securityQuestions`, {
    hierarchy: false,
    method: 'post',
    data,
    error: true,
    allowNoToken: true
  });

export const updateDefaultPassword = data =>
  v4APICall(`/userAccounts/updateDefaultPassword`, {
    method: 'put',
    data,
    error: true,
    toast: true
  });

export const doesUsernameExist = username =>
  v4APICall(`/userAccounts/doesUsernameExist/${username}`, {
    error: true,
    loading: false
  });

export const createUserSecurityQuestions = data =>
  v4APICall(`/userAccounts/createSecurityQuestions`, {
    method: 'post',
    data,
    error: true,
    toast: true
  });

export const externalStatementLogin = data =>
  v4APICall(`/userAccounts/externalLogin`, {
    hierarchy: false,
    method: 'put',
    data,
    error: true,
    allowNoToken: true
  });

export const checkForValidLink = token =>
  v4APICall(`/userAccounts/validateLink/${token}`, {
    hierarchy: false,
    error: true,
    allowNoToken: true
  });

// list filters
export const fetchUserListFilter = (empId, type) =>
  v4APICall(`/listFilter/${empId}/${type}`, {
    hierarchy: 'company',
    error: true
  });

export const updateUserListFilter = data =>
  v4APICall(`/listFilter`, {
    hierarchy: 'company',
    method: 'put',
    data,
    error: true,
    toast: true
  });

//expectations
export const getCompanyExpectations = () =>
  v4APICall(`/expectation`, {
    hierarchy: 'company',
    error: true
  });

export const getUserExpectations = userId =>
  v4APICall(`/expectation/${userId}`, {
    hierarchy: 'company',
    error: true
  });

export const createExpectations = data =>
  v4APICall(`/expectation`, {
    hierarchy: 'company',
    method: 'POST',
    error: true,
    data
  });

export const updateExpectations = data =>
  v4APICall(`/expectation/${data?._id}`, {
    hierarchy: 'company',
    method: 'PUT',
    error: true,
    data
  });

// exceptionReports

export const getExceptionReports = type =>
  v4APICall(`/exceptionReport/fetch/${type}`, {
    hierarchy: 'company',
    method: 'post',
    error: true
  });

export const createExceptionReport = data =>
  v4APICall(`/exceptionReport/create`, {
    hierarchy: 'company',
    method: 'post',
    data,
    error: true
  });

export const updateExceptionReport = data =>
  v4APICall(`/exceptionReport/${data?._id}`, {
    method: 'put',
    data,
    error: true
  });
