import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from '../../../history';

import {
  checkJobTitleGroupPairs,
  createTraining,
  createTrainingRepeatable,
  createTrainingScanOnDemand,
  fetchTrainingById,
  updateTraining
} from '../../../api/v4';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../../selectors/company';
import { getLoggedInUser } from '../../../selectors/users';
import { TRAINING_CREATION_STAGES } from '../../../constants/constants';

import BasicTrainingCard from '../../../components/training/Cards/Basic';
import { EmployeeDropdown, TwoColumn } from '../../../components/inputs';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import HierarchySelector from '../../../components/HierarchySelector';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';
import Modal from '../../../components/Modal';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import ScheduleDigitalCard from '../../../components/training/Cards/Schedule/Digital';
import ScheduleInPersonCard from '../../../components/training/Cards/Schedule/In-Person';
import TrainingStages from '../../../components/training/TrainingStages';
import TrainingDetails from '../../../components/training/Cards/Details';

import styles from './styles.module.scss';

export default function TrainingCreate() {
  const activeCompany = useSelector(getActiveCompany);
  const group = useSelector(getActiveLocationId);
  const project = useSelector(getActiveProject);
  const user = useSelector(getLoggedInUser);
  const location = useLocation();

  const { trainingId } = useParams();
  const [selectedStage, setStage] = useState(0);
  const [training, setTraining] = useState({});
  const [unsavedChanges, setUnsaved] = useState(false);
  const [stages] = useState(TRAINING_CREATION_STAGES);
  const [unsavedModal, setUnsavedModal] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [jobTitleModal, setJobTitleModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [completedStages, setCompletedStages] = useState([]);
  const [employees, setEmployees] = useState([]);
  const equipmentId = location.state?.equipmentId;
  const validTemplateIds = location.state?.validTemplateIds;
  const fromScreen = location.state?.fromScreen;
  const equipmentGroupDropdown = location?.state?.equipmentGroupDropdown;

  useEffect(() => {
    const storedTraining = localStorage.getItem('trainingTemplate');
    let training = {
      trainingType: equipmentId ? 1 : null,
      schedule: equipmentId ? 1 : null,
      equipmentId: equipmentId
    };
    if (storedTraining) {
      const jsonTraining = JSON.parse(storedTraining);
      setTraining({ ...training, ...jsonTraining });
      setStage(jsonTraining.stage ?? TRAINING_CREATION_STAGES?.length - 1);
      setCompletedStages(jsonTraining?.completedStages ?? []);
      localStorage.removeItem('trainingTemplate');
    } else if (trainingId)
      fetchTrainingById(trainingId).then(r =>
        setTraining({ ...training, ...r })
      );
    else {
      setTraining(training);
    }
  }, [trainingId, equipmentId, group, project]);

  const onAnswer = value => {
    let updated = { ...training, ...value };

    if (
      updated.trainingType === 2 &&
      !updated.quizMasterId &&
      !updated.collectSignatures
    )
      delete updated.releaseType;

    if (value?.trainingType && value?.trainingType !== training?.trainingType) {
      setCompletedStages([]);
    }

    setTraining(updated);
    setUnsaved(true);
    setDisabled(false);
  };
  const handleBack = () => {
    history.push(fromScreen ?? '/app/training/list');
  };

  const handleSubmit = () => {
    let trainingObj = {
      ...training,
      companyId: activeCompany._id
    };
    if (!trainingId) {
      if (trainingObj.schedule === 3)
        createTrainingScanOnDemand(trainingObj).then(handleBack);
      else if (trainingObj.trainingType === 1 && trainingObj.schedule === 2)
        createTrainingRepeatable(trainingObj).then(handleBack);
      else handleDigitalCreate(trainingObj);
    } else
      updateTraining({
        ...training,
        companyId: activeCompany._id
      }).then(handleBack);
  };

  const handleDigitalCreate = trainingObj => {
    if (trainingObj.assignBy === 1 && trainingObj?.trainingType === 1)
      checkJobTitleGroupPairs(trainingObj).then(r => {
        if (r?.length) {
          setJobTitleModal(true);
          setEmployees(r);
        } else {
          createTraining(trainingObj).then(handleBack);
        }
      });
    else createTraining(trainingObj).then(handleBack);
  };

  const advancePage = (stageSelected = null) => {
    let stageToGoTo = stageSelected ?? selectedStage + 1;
    setCompletedStages([...completedStages, selectedStage]);
    setStage(stageToGoTo);
    setMissingRequired(false);
    setDisabled(true);
  };

  const handleStageSelect = stage => {
    if (stage === selectedStage) {
      return;
    } else if (stage < selectedStage) {
      setStage(stage);
    } else if (canSubmit && stage === selectedStage + 1) {
      advancePage();
    } else if (completedStages?.includes(stage - 1)) {
      advancePage(stage);
    }
  };

  const header = (
    <Header
      title="Training"
      section="Create Training"
      needsSaved={unsavedChanges}
      clickBack={() =>
        selectedStage === 0
          ? unsavedChanges
            ? setUnsavedModal(true)
            : handleBack()
          : setStage(selectedStage - 1)
      }
    />
  );
  const footer = (
    <SaveCancelFooter
      saveButtonText={selectedStage === stages.length - 1 ? 'Create' : 'Next'}
      saveButtonClick={() =>
        !canSubmit
          ? setMissingModalOpen(true)
          : selectedStage === stages.length - 1
            ? handleSubmit()
            : advancePage()
      }
      cancelButtonClick={() =>
        selectedStage === 0
          ? unsavedChanges
            ? setUnsavedModal(true)
            : handleBack()
          : setStage(selectedStage - 1)
      }
      cancelButtonText={selectedStage === 0 ? 'Cancel' : 'Back'}
      reverseOrder
      onMouseEnter={() => setMissingRequired(true)}
      // disabling is required because the onMouseEnter fires when you go to the next card
      saveButtonDisabled={disabled && !canSubmit}
    />
  );

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        className={styles.trainingContainer}
      >
        <TrainingStages
          stages={stages}
          selectedStage={selectedStage}
          missingRequired={missingRequired}
          onClick={handleStageSelect}
          completedStages={completedStages}
        />
        {selectedStage === 0 ? (
          <BasicTrainingCard
            training={training}
            onAnswer={onAnswer}
            setCanSubmit={setCanSubmit}
            onlyTemplates={equipmentId ? (validTemplateIds ?? []) : undefined}
            missingRequired={missingRequired}
          />
        ) : selectedStage === 1 ? (
          <TrainingDetails
            training={training}
            onAnswer={onAnswer}
            setCanSubmit={setCanSubmit}
            location={group}
            editing
            creating
            missingRequired={missingRequired}
            completedStages={completedStages}
          />
        ) : selectedStage === 2 ? (
          training?.trainingType === 1 ? (
            <ScheduleDigitalCard
              training={training}
              onAnswer={onAnswer}
              company={activeCompany}
              groupDropdown={equipmentGroupDropdown}
              setCanSubmit={setCanSubmit}
              location={group}
              editing
              creating
              accessLevel={user.accessLevel}
              missingRequired={missingRequired}
            />
          ) : (
            <ScheduleInPersonCard
              training={training}
              onAnswer={onAnswer}
              setCanSubmit={setCanSubmit}
              company={activeCompany}
              location={group}
              editing
              creating
              accessLevel={user.accessLevel}
              missingRequired={missingRequired}
            />
          )
        ) : (
          <></>
        )}
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={unsavedModal}
        onRequestClose={() => setUnsavedModal(false)}
        submitActions={() => handleBack()}
        savingWhat="a page"
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Training"
      />
      <Modal
        title="Choose Group"
        titleClassName="redHeader"
        isOpen={jobTitleModal}
        submitButtonColor="green"
        onRequestClose={() => setJobTitleModal(false)}
        submitActions={() =>
          createTraining({
            ...training,
            companyId: activeCompany._id,
            selectedEmpGroup: employees
          }).then(handleBack)
        }
      >
        <>
          <b>
            The following users are apart of multiple groups selected. Please
            choose which group you would like their training assigned in.
          </b>
          {employees?.map((e, i) => {
            const groupDropdown = activeCompany?.groups
              ?.filter(g => e?.groupIds?.includes(g._id))
              ?.map(g => ({ label: g.name, value: g._id }));
            return (
              <TwoColumn>
                <EmployeeDropdown
                  fieldLabel="Employee"
                  currentValue={e?._id}
                  searchable
                  name="dropdownEmployees"
                  isRequired
                  disabled
                />
                <HierarchySelector
                  groupId={employees[i]?.selectedGroupId}
                  filteredGroupDropdown={groupDropdown}
                  showProject={false}
                  noTwoColumn
                  onGroupChange={groupId => {
                    let updated = { ...employees[i], selectedGroupId: groupId };
                    setEmployees(employees.toSpliced(i, 1, updated));
                  }}
                />
              </TwoColumn>
            );
          })}
        </>
      </Modal>
    </>
  );
}
