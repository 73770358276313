import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import {
  archiveMany,
  fetchTrainingRepeatables,
  fetchTrainingScanOnDemands,
  fetchAllTrainings,
  fetchDocumentFoldersByType,
  fetchUserListFilter,
  importHistoricalTrainings,
  updateTraining,
  updateTrainingScanOnDemand,
  updateTrainingRepeatable,
  updateDocument,
  updateUserListFilter
} from '../../../api/v4';
import history from '../../../history';
import {
  TRAINING_BULK_UPLOAD_TEMPLATE,
  TRAINING_CATEGORY_DROPDOWN
} from '../../../constants/constants';
import {
  getAddedAttachmentsSelector,
  getAttachmentLoadingSelector
} from '../../../selectors/attachments';
import { getActiveFolderSelector } from '../../../selectors/documents';
import { getLoggedInUser } from '../../../selectors/users';
import { clearUploadedAttachments } from '../../../actions/attachments';
import { usePrevious } from '../../../utils/hooks';
import useActiveHeirarchy from '../../../utils/useActiveHeirarchy';
import customToast from '../../../utils/customToast';
import { validListDateRange } from '../../../utils/formHelpers';
import AnalyticsPeriodPicker from '../../../components/AnalyticsPeriodPicker';
import AddToFolderModal from '../../../components/Modal/addToFolderModal';
import { Button } from '../../../components/inputs';
import CSVUploader from '../../../components/CSVUploader';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import DocumentFolder from '../../../components/DocumentFolder';
import List from '../../../components/List';
import Modal from '../../../components/Modal';
import SafetyTrainingList from '../../../components/training/SafetyTrainingList';
import TypeSelection from '../../../components/TypeSelection';
import RepeatableList from '../../../components/RepeatableList';
import ImportProblemsModal from '../../../components/Modal/importProblemsModal';

export default function TrainingList() {
  const dispatch = useDispatch();

  const [allTrainings, setTrainings] = useState([]);
  const [folders, setFolders] = useState([]);
  const [trainingType, setTrainingType] = useState('Active');
  const [openModalType, setOpenModalType] = useState();
  const [documentFolderId, setFolderId] = useState('');
  const [addToFolder, setAddFolder] = useState([]);
  const [repeatingTrainings, setRepeatingTrainings] = useState([]);
  const [SODTrainings, setSOD] = useState([]);
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const [messages, setMessages] = useState([]);

  const activeFolder = useSelector(getActiveFolderSelector);
  const user = useSelector(getLoggedInUser);
  const attachmentLoading = useSelector(getAttachmentLoadingSelector);
  const prevPeriod = usePrevious(currentPeriod);
  const prevFolder = usePrevious(activeFolder);
  const prevType = usePrevious(trainingType);
  const attachments = useSelector(getAddedAttachmentsSelector);

  const { activeGroup, activeProject } = useActiveHeirarchy();

  useEffect(() => {
    fetchUserListFilter(user._id, 'training').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });
  }, [user._id]);

  useEffect(() => {
    let hasStateChange =
      !isEqual(prevPeriod, currentPeriod) ||
      !isEqual(prevFolder, activeFolder) ||
      !isEqual(prevType, trainingType);

    if (
      currentPeriod &&
      validListDateRange(currentPeriod) &&
      hasStateChange &&
      (trainingType === 'Active' || trainingType === 'Archived')
    ) {
      fetchAllTrainings(activeFolder._id, {
        ...currentPeriod,
        selectedType: trainingType
      }).then(res => {
        setTrainings(res);
      });
    }

    fetchDocumentFoldersByType('SafetyTraining').then(response =>
      setFolders(response)
    );

    fetchTrainingRepeatables(activeFolder._id).then(response =>
      setRepeatingTrainings(response)
    );

    fetchTrainingScanOnDemands(activeFolder._id).then(response =>
      setSOD(response)
    );
  }, [
    activeFolder,
    currentPeriod,
    prevPeriod,
    prevFolder,
    trainingType,
    prevType
  ]);

  const getTraining = schedule => {
    if (schedule === 'Active' || schedule === 'Archived')
      return getImmediateTable();
    else if (schedule === 'Repeating') return getRepeatingTable();
    return getScanTable();
  };

  const handleAddMultipleToFolders = (trainingType = 'Active') => {
    let addDocsToFolder = [];

    let trainingList =
      trainingType === 'Active'
        ? allTrainings
        : trainingType === 'Repeating'
          ? repeatingTrainings
          : SODTrainings;

    trainingList.forEach(document => {
      if (addToFolder.indexOf(document._id) > -1) {
        addDocsToFolder.push(document);
      }
    });

    for (const doc of addDocsToFolder) {
      switch (trainingType) {
        case 'Active':
          updateTraining({
            ...doc,
            fromAddFolder: true,
            documentFolderId: documentFolderId
          });
          break;
        case 'Repeating':
          updateTrainingRepeatable({
            ...doc,
            type: 'trainings',
            documentFolderId: documentFolderId,
            fromAddFolder: true
          });
          break;
        case 'On Demand':
          updateTrainingScanOnDemand({
            ...doc,
            type: 'trainings',
            documentFolderId: documentFolderId,
            fromAddFolder: true
          });
          break;

        default:
          updateDocument({ ...doc, documentFolderId: documentFolderId });
          break;
      }
    }
    setOpenModalType();
    setFolderId('');
    setAddFolder([]);

    fetchTrainingRepeatables().then(response =>
      setRepeatingTrainings(response)
    );
    fetchTrainingScanOnDemands().then(response => setSOD(response));
  };

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'training',
      ids: ids,
      isArchived: trainingType === 'Active'
    }).then(() =>
      fetchAllTrainings(activeFolder._id, {
        ...currentPeriod,
        selectedType: trainingType
      }).then(setTrainings)
    );
  };

  const getImmediateTable = () => {
    return (
      <SafetyTrainingList
        trainings={allTrainings}
        addToFolder={ids => {
          setAddFolder(ids);
          setOpenModalType('folders');
        }}
        saveKey="trainingList"
        accessLevel={user.accessLevel}
        isArchivedList={trainingType === 'Archived'}
        handleArchive={handleArchive}
      />
    );
  };

  const getRepeatingTable = () => {
    const repeatingColumns = [
      { key: 'title', label: 'Training Title' },
      {
        key: 'category',
        label: 'Training Category',
        accessor: t =>
          TRAINING_CATEGORY_DROPDOWN.find(c => c.value === t?.category)?.label,
        enum: TRAINING_CATEGORY_DROPDOWN.map(c => c.label)
      },
      {
        key: 'assignedTo',
        label: 'Employees',
        datatype: 'users',
        accessor: r => r?.assignedTo
      },
      {
        key: 'assignByJobTitles',
        label: 'Job Title',
        accessor: r => r?.training?.assignByJobTitles?.join(', ')
      },
      { key: 'lastUpdatedAt', label: 'Last Updated', datatype: 'date' },
      {
        key: 'repeatingOn',
        label: 'Repeat On',
        datatype: 'dow',
        frequency: 'frequency'
      }
    ];

    return (
      <RepeatableList
        repeatables={repeatingTrainings}
        repeatingColumns={repeatingColumns}
        handleRowClick={handleViewEdit}
        saveKey="repeatingTrainingList"
        type="Safety Trainings"
        updateData={() =>
          fetchTrainingRepeatables().then(response =>
            setRepeatingTrainings(response)
          )
        }
        actions={[
          {
            label: 'Add to Folder',
            color: 'blue',
            onClick: ids => {
              setAddFolder(ids);
              setOpenModalType('folders');
            }
          }
        ]}
      />
    );
  };

  const getScanTable = () => {
    const scanColumns = [
      { key: 'title', label: 'Training Title' },
      { key: 'lastUpdatedAt', label: 'Last Updated', datatype: 'date' },
      {
        key: 'category',
        label: 'Training Category',
        accessor: t =>
          TRAINING_CATEGORY_DROPDOWN.find(c => c.value === t.category)?.label ??
          '',
        enum: TRAINING_CATEGORY_DROPDOWN.map(c => c.label)
      },
      {
        key: 'qrCodeUrl',
        label: 'QR Code',
        datatype: 'url',
        text: 'Download QR Code'
      }
    ];

    return (
      <List
        saveKey="scanTrainingList"
        data={SODTrainings}
        dataIsHash
        settings={scanColumns}
        rowClick={handleViewEdit}
        getRowId={r => r._id}
        actions={[
          {
            label: 'Add to Folder',
            color: 'blue',
            onClick: ids => {
              setAddFolder(ids);
              setOpenModalType('folders');
            }
          }
        ]}
      />
    );
  };

  const handleViewEdit = async template => {
    if (Object.keys(template).length === 0) {
      history.push(`/app/training/create/`);
      return;
    } else if (trainingType === 'Repeating') {
      history.push(`/app/training/view/${template._id}/repeating`);
      return;
    } else if (trainingType === 'On Demand') {
      history.push(`/app/training/view/${template._id}/sod`);
      return;
    }
    history.push(`/app/training/view/${template._id}`);
  };

  const handleImport = () => {
    try {
      if (!attachments[0]) {
        customToast('File Required', 'error');
      } else {
        importHistoricalTrainings(attachments[0]?.source_url).then(
          data => {
            customToast('Imported successfully!', 'success');

            fetchAllTrainings(activeFolder._id, {
              ...currentPeriod,
              selectedType: trainingType
            }).then(res => {
              setTrainings(res);
            });
          },
          error => {
            setMessages(error.data);
            setOpenModalType('importProblems');
          }
        );
      }
    } catch (e) {
      if (e.status !== 400) throw e;

      let messages = e.data;
      if (!Array.isArray(messages)) messages = [messages];
      setMessages(messages);
      setOpenModalType('importProblems');
    }

    dispatch(clearUploadedAttachments());
    setOpenModalType();
  };

  const header = (
    <Header
      section="Trainings"
      pageActionOptions={[
        {
          label: 'Create Training',
          visible: !activeGroup?.isHidden && !activeProject?.isHidden,
          color: 'greenOutline',
          onClick: () => {
            localStorage.removeItem('trainingTemplate');
            handleViewEdit({});
          }
        },
        {
          label: 'Upload Historical Trainings',
          visible:
            !activeGroup?.isHidden &&
            !activeProject?.isHidden &&
            user?.accessLevel === 900,
          color: 'blueOutline',
          onClick: () => setOpenModalType('importTrainings')
        }
      ]}
    />
  );
  return (
    <HeaderAndFooter Header={header}>
      <TypeSelection
        selected={trainingType}
        selectedArray={[
          'Active',
          'Repeating',
          'On Demand',
          user.accessLevel > 400 ? 'Archived' : null
        ]}
        onClick={value => {
          setTrainingType(value);
          if (value !== trainingType) setTrainings([]);
        }}
      />
      {trainingType === 'Active' || trainingType === 'Archived' ? (
        <AnalyticsPeriodPicker
          period={currentPeriod}
          onChange={v => {
            setTrainings([]);
            setCurrentPeriod(v);
          }}
          onSave={v =>
            updateUserListFilter({
              empId: user._id,
              type: 'training',
              period: v
            }).then(() => setEmpPeriod(v))
          }
          allowAll
          savedPeriod={empPeriod}
          listPicker
        />
      ) : null}
      <DocumentFolder content={allTrainings} docType="Safety Training" />
      {getTraining(trainingType)}
      <AddToFolderModal
        isOpen={openModalType === 'folders'}
        folderId={documentFolderId}
        onRequestClose={() => setOpenModalType()}
        submitActions={() => handleAddMultipleToFolders(trainingType)}
        folderOptions={folders.map(folder => {
          return {
            value: folder._id,
            label: folder.name
          };
        })}
        onChange={value => setFolderId(value)}
      />
      <Modal
        title="Upload Historical Trainings"
        titleClassName="blueHeader"
        isOpen={openModalType === 'importTrainings'}
        className="modalSmall"
        onRequestClose={() => {
          dispatch(clearUploadedAttachments());
          setOpenModalType();
        }}
        submitButtonColor="blue"
        submitActions={handleImport}
        disableSubmit={attachmentLoading || !attachments?.length}
      >
        <Button
          color="blue"
          onClick={() => {
            var win = window.open(TRAINING_BULK_UPLOAD_TEMPLATE, '_blank');
            win.focus();
          }}
          text="Download CSV Template"
        />
        <CSVUploader
          documentType="HistoricalTrainingUpload"
          className="dropzone"
        />
      </Modal>

      <ImportProblemsModal
        type="Trainings"
        isOpen={openModalType === 'importProblems'}
        onRequestClose={() => setOpenModalType()}
        messages={messages}
      />
    </HeaderAndFooter>
  );
}
