import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isValid, submit } from 'redux-form';
import { escapeRegExp } from 'lodash';
import classnames from 'classnames/bind';
import Loading from '../../components/Loading';
import {
  fetchAllUsersForLocation,
  uploadCompanyLogo,
  createUserSecurityQuestions
} from '../../api/v4';
import history from '../../history';
import {
  addUploadedAttachmentsResponse,
  clearUploadedAttachments
} from '../../actions/attachments';
import {
  addCompanyLocationRequest,
  addCompanyRequest,
  addProjectRequest,
  setActiveCompany,
  setActiveGroups,
  setActiveLocation,
  setActiveProject,
  setAllLocations,
  setIncidentOwner
} from '../../actions/company';
import { updatePasswordRequest } from '../../actions/user';
import {
  SECURITY_QUESTIONS_OPTIONS_SET_ONE,
  SECURITY_QUESTIONS_OPTIONS_SET_TWO
} from '../../constants/constants';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { isFirstTimeLogin } from '../../selectors/auth';
import { getUserCompaniesSelector } from '../../selectors/company';
import { getLoggedInUser } from '../../selectors/users';
import customToast from '../../utils/customToast';
import AreaModal from '../../components/Modal/AreaModal';
import CompanyLogo from '../../components/CompanyLogo';
import CompanyCard from '../../components/CompanyCard';
import CompanyForm from '../../forms/CompanyForm';
import GroupModal from '../../components/Modal/groupModal';
import Modal from '../../components/Modal';
import ChangePasswordForm from '../../forms/ChangePasswordForm';
import { Textbox, Dropdown } from '../../components/inputs';
import ReassignUserModal from '../../components/Modal/reassignUserModal';

import styles from './home.module.scss';
const bStyles = classnames.bind(styles);

export default function Home(props) {
  const dispatch = useDispatch();
  const allLocations = values => dispatch(setAllLocations(values));
  const setIncidentOwn = payload => dispatch(setIncidentOwner(payload));
  const setCompany = company => dispatch(setActiveCompany(company));
  const setLocation = locationId => dispatch(setActiveLocation(locationId));
  const setAddedAttachments = atts =>
    dispatch(addUploadedAttachmentsResponse(atts));
  const addCompany = values => dispatch(addCompanyRequest(values));
  const addCompanyLocation = values =>
    dispatch(addCompanyLocationRequest(values));
  const removeAttachment = () => dispatch(clearUploadedAttachments());
  const setGroups = values => dispatch(setActiveGroups(values));
  const addProject = values => dispatch(addProjectRequest(values));
  const setProject = project => dispatch(setActiveProject(project));
  const submitCompany = () => dispatch(submit('CompanyForm'));
  const submitPassword = values => dispatch(updatePasswordRequest(values));

  const userCompanies = useSelector(getUserCompaniesSelector);
  const user = useSelector(getLoggedInUser);
  const firstTimeLogin = useSelector(isFirstTimeLogin);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);
  const companyValid = useSelector(isValid('CompanyForm'));

  const [modalOpen, setModalOpen] = useState(0);
  const [modalCompany, setModalCompany] = useState({});
  const [modalGroup, setModalGroup] = useState({});
  const [assignIncidentOwnerModal, setAssignIncidentOwnerModal] =
    useState(false);
  const [reassignUser, setReassignUser] = useState(undefined);
  const [securityQuestionsModalOpen, setSecurityQuestionsModalOpen] =
    useState(false);
  const [securityQuestion1, setSecurityQuestion1] = useState();
  const [securityQuestion2, setSecurityQuestion2] = useState();
  const [securityAnswer1, setSecurityAnswer1] = useState();
  const [securityAnswer2, setSecurityAnswer2] = useState();
  const [users, setUsers] = useState(null);
  const [hierarchyId, setHierarchyId] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [missingRequired, setMissingRequired] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filterQuery, setFilterQuery] = useState();

  useEffect(() => {
    if (firstTimeLogin && !user.companies) {
      setModalOpen(4);
    } else if (userCompanies?.every(c => !!c.allGroupsHidden)) {
      customToast(
        'All groups you had access to have been removed. Please contact your iReportSource administrator.',
        'error'
      );
    } else if (
      !user.email &&
      !user.phoneNumber &&
      !user.securityQuestions &&
      !user.isMicrosoftUser
    ) {
      setSecurityQuestionsModalOpen(true);
    }
    setFilteredCompanies(userCompanies);
  }, [
    firstTimeLogin,
    user.companies,
    user.email,
    user.isMicrosoftUser,
    user.phoneNumber,
    user.securityQuestions,
    userCompanies
  ]);

  const handleOpenModal = (company, group, modalId) => {
    setModalOpen(modalId);
    setModalCompany(company);
    setModalGroup(group);
  };

  const handleCloseModal = () => {
    setModalOpen(0);
    removeAttachment();
  };

  const handleGoToDashboard = () => {
    handleCloseModal();
    history.push('/app/dashboard');
  };

  const handleNewCompany = async values => {
    await addCompany({ ...values, isOnboarding: true });
    handleOpenModal({}, {}, 1);
  };

  const handleNewGroup = async values => {
    await addCompanyLocation(values);
    handleGoToDashboard();
  };

  const handleUpdateState = stateObject => {
    this.setState(stateObject);
  };

  const handleProjectSelection = project => {
    setCompany(this.state.modalCompany);
    setLocation(this.state.modalGroup);
    setProject(project);
    this.props?.location?.state?.fromScreen === 'login'
      ? history.push({
          pathname: '/app/dashboard',
          state: {
            showToasts: true,
            showNotificationModal: !user.notifications
          }
        })
      : history.push('/app/dashboard');
  };

  const handleCompanyLogoUpload = att => {
    //using a different api call to send data to auth endpoint, not requiring company yet
    uploadCompanyLogo(att.data, {
      ownerId: att.ownerId,
      ownerType: att.ownerType,
      isSignature: att.isSiganture ?? false,
      isPhotoEvidence: att.isPhotoEvidence ?? false,
      photoType: att?.photoType
    }).then(r => {
      setAddedAttachments([...this.props.addedAttachments, ...r]);
    });
  };

  const handleNewCompanySubmit = (values, user) => {
    addCompany({ ...values });
    handleCloseModal();
  };

  const handleNewLocationSubmit = (values, user, modalCompany) => {
    setCompany(modalCompany);
    addCompanyLocation(values);
    handleCloseModal();
  };

  const handleNewProjectSubmit = values => {
    addProject(values);
    handleCloseModal();
  };

  const openAssigneeModal = (hierarchy, currentValue) => {
    const hierarchyId = hierarchy?.companyId ?? hierarchy?.locationId;
    setUsers(null);
    setAssignIncidentOwnerModal(true);
    setReassignUser(currentValue);
    setHierarchyId(hierarchyId);

    fetchAllUsersForLocation(hierarchyId, {
      ...hierarchy,
      accessLevel: [500, 900]
    }).then(setUsers);
  };

  const submitDefaultIncidentOwner = () => {
    setIncidentOwn({
      locationId: hierarchyId,
      employeeId: reassignUser
    });
    setAssignIncidentOwnerModal(false);
  };

  const locationName = name => {
    if (!filterQuery?.length) return name;
    const regex = new RegExp(`(${escapeRegExp(filterQuery)})`, 'gi');
    const formatted = name.split(regex);
    return (
      <>
        {formatted.map((t, i) =>
          regex.test(t) ? (
            // louisa made me choose this color
            <mark style={{ backgroundColor: '#FFCC00', opacity: '80%' }}>
              {t}
            </mark>
          ) : (
            <span>{t}</span>
          )
        )}
      </>
    );
  };

  const handleFiltering = query => {
    setFilterQuery(query);
    setFilteredCompanies(
      userCompanies.filter(
        c =>
          c.name.toLowerCase().includes(query.toLowerCase()) ||
          c.groups.some(
            g =>
              g.name.toLowerCase().includes(query.toLowerCase()) && !g.isHidden
          )
      )
    );
  };

  const hideGroup = (company, location) => {
    if (
      filterQuery &&
      !location.name.toLowerCase().includes(filterQuery.toLowerCase()) &&
      company.groups.some(g =>
        g.name.toLowerCase().includes(filterQuery.toLowerCase())
      )
    )
      return true;

    return false;
  };

  const canSubmitSecurityQuestions =
    securityQuestion1 &&
    securityAnswer1?.trim() &&
    securityQuestion2 &&
    securityAnswer2?.trim();

  const canSubmitPassword =
    newPassword?.trim().length &&
    confirmPassword?.trim().length &&
    newPassword === confirmPassword;

  return (
    <div className={styles.home}>
      <form autocomplete="off" onSubmit={e => e.preventDefault()}>
        <Textbox
          currentValue={filterQuery}
          onChange={handleFiltering}
          placeholder="Search by Company/Division or Group/Establishment..."
          className={styles.searchBar}
          autoComplete="off"
          name="homeSearchField"
        />
      </form>
      {!filteredCompanies?.length ? (
        filterQuery ? (
          <p>No results match that query</p>
        ) : (
          <Loading />
        )
      ) : (
        filteredCompanies?.map((company, index) => (
          <div key={index} className={styles.companies}>
            <CompanyLogo
              company={company}
              setActiveCompany={setCompany}
              setActiveLocation={setLocation}
              setActiveProject={setProject}
              allLocations={allLocations}
              setActiveGroups={setGroups}
              user={user}
              assignIncidentOwner={value =>
                openAssigneeModal({ companyId: company._id }, value)
              }
              fromLoginScreen={props?.location?.state?.fromScreen === 'login'}
              companyName={locationName(company.name)}
            />
            <div className={styles.locations}>
              <h3>Long Term Establishment</h3>
              <div className={styles.establishments}>
                {company?.groups
                  ?.filter(
                    group =>
                      !group.isHidden &&
                      group.isSeparateEstablishment &&
                      group.operationalForYearOrLonger
                  )
                  .map((location, index) => {
                    if (hideGroup(company, location)) return null;
                    return (
                      <div className={styles['location-outerDiv']} key={index}>
                        <CompanyCard
                          location={location}
                          company={company}
                          setActiveCompany={setCompany}
                          setActiveLocation={setLocation}
                          setActiveProject={setProject}
                          allLocations={allLocations}
                          setActiveGroups={setGroups}
                          openProjectModal={() =>
                            handleOpenModal(company, location, 5)
                          }
                          openProjectDropdown={() =>
                            handleUpdateState({
                              modalCompany: company,
                              modalGroup: location
                            })
                          }
                          handleProjectSelection={project =>
                            handleProjectSelection(project)
                          }
                          user={user}
                          assignIncidentOwner={value =>
                            user.accessLevel > 400 &&
                            openAssigneeModal(
                              { locationId: location._id },
                              value
                            )
                          }
                          fromLoginScreen={
                            props?.location?.state?.fromScreen === 'login'
                          }
                          groupName={locationName(location.name)}
                        />
                      </div>
                    );
                  })}
                {user.accessLevel === 900 ? (
                  <div
                    onClick={() => handleOpenModal(company, {}, 1)}
                    className={bStyles('location-button', 'newLocation')}
                  >
                    <div>Add Long Term Establishment</div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
              <h3>Short Term Establishment</h3>
              <div className={styles.establishments}>
                {company?.groups
                  ?.filter(
                    group =>
                      !group.isHidden &&
                      (!group.isSeparateEstablishment ||
                        (!group.operationalForYearOrLonger &&
                          group.isSeparateEstablishment))
                  )
                  .map((location, index) => {
                    if (hideGroup(company, location)) return null;
                    return (
                      <div className={styles['location-outerDiv']} key={index}>
                        <CompanyCard
                          location={location}
                          company={company}
                          setActiveCompany={setCompany}
                          setActiveLocation={setLocation}
                          allLocations={allLocations}
                          setActiveGroups={setGroups}
                          setActiveProject={setProject}
                          openProjectModal={() =>
                            handleOpenModal(company, location, 5)
                          }
                          openProjectDropdown={() =>
                            handleUpdateState({
                              modalCompany: company,
                              modalGroup: location
                            })
                          }
                          handleProjectSelection={project =>
                            handleProjectSelection(project)
                          }
                          user={user}
                          assignIncidentOwner={value =>
                            openAssigneeModal(
                              { locationId: location._id },
                              value
                            )
                          }
                          fromLoginScreen={
                            props?.location?.state?.fromScreen === 'login'
                          }
                          groupName={locationName(location.name)}
                        />
                      </div>
                    );
                  })}
                {user.accessLevel === 900 ? (
                  <div
                    onClick={() => handleOpenModal(company, {}, 1)}
                    className={bStyles('location-button', 'newLocation')}
                  >
                    <div>
                      <p>Add a New Group</p>
                      <p>– or –</p>
                      <p>Short Term Establishment</p>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        ))
      )}
      <GroupModal
        isOpen={modalOpen === 1}
        onRequestClose={() => handleCloseModal()}
        submitActions={values =>
          firstTimeLogin
            ? handleNewGroup(values)
            : handleNewLocationSubmit(values, user, modalCompany)
        }
        isPerrp={modalCompany.reportingType === 2}
      />
      <AreaModal
        isOpen={modalOpen === 5}
        onRequestClose={() => handleCloseModal()}
        currentArea={{
          companyId: modalCompany._id,
          groupId: modalGroup._id
        }}
        submitActions={values => handleNewProjectSubmit(values)}
        isPerrp={modalCompany.reportingType === 2}
        company={modalCompany}
        fromHomePage
      />
      {securityQuestionsModalOpen && (
        <Modal
          title="Answer Security Questions"
          titleClassName="blueHeader"
          isOpen={securityQuestionsModalOpen}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          hideCancelButton
          submitActions={() => {
            if (canSubmitSecurityQuestions) {
              const questionsAnswers = {
                questions: [securityQuestion1.label, securityQuestion2.label],
                answers: [
                  {
                    question: securityQuestion1.label,
                    answer: securityAnswer1
                  },
                  {
                    question: securityQuestion2.label,
                    answer: securityAnswer2
                  }
                ]
              };
              user.securityQuestions = questionsAnswers.questions;
              user.securityAnswers = questionsAnswers.answers;

              createUserSecurityQuestions(user).then(r =>
                setSecurityQuestionsModalOpen(false)
              );
            }
          }}
          onMouseEnter={() => setMissingRequired(!canSubmitSecurityQuestions)}
          submitTooltip={missingRequired ? 'Missing or Invalid Fields' : null}
        >
          <p>
            These security questions are for you to be able to reset your
            password in case you forget it. The answers provided here will not
            be shared with others.
          </p>
          <Dropdown
            options={SECURITY_QUESTIONS_OPTIONS_SET_ONE}
            fieldLabel="Security Question 1"
            onChange={e => {
              if (securityQuestion2 === e.label) {
                alert('Questions cannot be the same');
                e.value = null;
                e.label = null;
                return;
              }
              setSecurityQuestion1(e);
            }}
            currentValue={securityQuestion1}
            bareValues={false}
            isRequired
            touched={missingRequired && !securityQuestion1}
          />
          <Textbox
            fieldLabel="Security Question Answer 1"
            onChange={v => setSecurityAnswer1(v)}
            isRequired={true}
            placeholder="Fill in with answer to Security Question 1"
            currentValue={securityAnswer1}
            touched={missingRequired && !securityAnswer1}
          />
          <Dropdown
            options={SECURITY_QUESTIONS_OPTIONS_SET_TWO}
            fieldLabel="Security Question 2"
            onChange={e => {
              if (securityQuestion1 === e.label) {
                alert('Questions cannot be the same');
                e.value = null;
                e.label = null;
                return;
              }
              setSecurityQuestion2(e);
            }}
            currentValue={securityQuestion2}
            bareValues={false}
            isRequired
            touched={missingRequired && !securityQuestion2}
          />
          <Textbox
            fieldLabel="Security Question Answer 2"
            onChange={v => setSecurityAnswer2(v)}
            isRequired
            placeholder="Fill in with answer to Security Question 2"
            currentValue={securityAnswer2}
            touched={missingRequired && !securityAnswer2}
          />
        </Modal>
      )}
      {modalOpen === 2 && (
        <Modal
          title="Add New Company Division"
          titleClassName="greenHeader"
          isOpen={modalOpen === 2}
          submitButtonColor="green"
          onRequestClose={handleCloseModal}
          disableSubmit={!companyValid}
          submitActions={submitCompany}
          submitButtonText="Create"
          hideCancelButton={firstTimeLogin}
        >
          <CompanyForm
            onSubmit={values =>
              firstTimeLogin
                ? handleNewCompany(values)
                : handleNewCompanySubmit(values, user)
            }
            redirect={false}
            addedAttachments={addedAttachments}
            addAttachment={handleCompanyLogoUpload}
            removeAttachment={removeAttachment}
          />
        </Modal>
      )}
      <Modal
        title="Thanks for trying iReport!"
        titleClassName="blueHeader"
        isOpen={modalOpen === 4}
        submitActions={() => handleOpenModal({}, {}, 2)}
        submitButtonText="Next"
        submitButtonColor="blue"
        hideCancelButton={true}
      >
        <p>
          We are going to gather some basic information so that you can try the
          app with ease.
        </p>
      </Modal>
      <ReassignUserModal
        title={
          reassignUser ? 'Reassign Incident Owner' : 'Assign Incident Owner'
        }
        onRequestClose={() => setAssignIncidentOwnerModal(false)}
        isOpen={assignIncidentOwnerModal}
        employeeFieldLabel="Default Incident Owner"
        submitActions={submitDefaultIncidentOwner}
        employees={users}
        onChange={value => setReassignUser(value)}
        message={
          <div className={styles['reportSectionContainer-emailAssigneeText']}>
            From here you can assign a {reassignUser ? 'different' : ''} user to
            be the default incident owner for anything reported within this
            Division/Establishment/Group and any children without a default
            incident owner. Incident ownership can only be assigned to
            Collaborators or Admins with access to the Establishment/Group of
            this incident.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              If there is no default incident owner for a
              Division/Establishment/Group, ownership will go to the default
              incident owner of the parent location. If this reaches all the way
              to company level, the Account Owner will be assigned as the
              incident owner.
            </span>
          </div>
        }
        disableSubmit={!reassignUser}
        reassignUser={reassignUser}
        permissions={[500, 900]}
      />
      <Modal
        testID="changePassword"
        title="Choose a New Password"
        titleClassName="blueHeader"
        className={styles.passwordModal}
        isOpen={user?.needsToUpdatePassword}
        submitButtonColor="green"
        onRequestClose={() => {
          customToast('You must change your password.', 'error');
        }}
        onMouseEnter={() => setMissingRequired(!canSubmitPassword)}
        submitTooltip={
          !newPassword || !confirmPassword
            ? 'Missing Field'
            : newPassword !== confirmPassword
              ? 'Passwords Do Not Match'
              : null
        }
        submitActions={() =>
          canSubmitPassword
            ? submitPassword({
                new_password: newPassword,
                confirm_password: confirmPassword
              })
            : null
        }
      >
        <ChangePasswordForm
          newPassword={newPassword}
          confirmPassword={confirmPassword}
          setNewPassword={v => setNewPassword(v)}
          setConfirmPassword={v => setConfirmPassword(v)}
          missingRequired={missingRequired}
        />
      </Modal>
    </div>
  );
}
